import React from 'react';
import { Switch, Route } from 'react-router-dom';

import OrderListPage from './OrderListPage';
import OrderPage from '../order/OrderPage';

const Orders = ({ match }) => (
  <Switch>
    <Route path={`${match.url}`} exact component={OrderListPage}/>
    <Route
      path={`${match.url}/:orderRef`} exact render={({ match }) => {
        return (<OrderPage orderRef={match.params.orderRef}/>);
      }}/>
  </Switch>
);

export default Orders;