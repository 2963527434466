import './Home.less';
const R = require('ramda');
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withIsMobile } from '../template/IsMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-light-svg-icons';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import url from 'url';
import RowOfItems from './elements/RowOfItems';
import { isNil } from 'ramda';

const Image = styled.div`
  border-radius: 4px;
`;

const OneImage = styled(Image)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-size: cover;
  background-position: center;
  width: 100%;
  //max-width: 1000px;
  height: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  opacity: 1;
  overflow: hidden;
  @media screen and (max-width: 700px) {
    height: 350px;
  }
`;

const OneImageTitle = styled.div`
  padding-top: 64px;
  color: white;
  font-size: 4.5vw;
  font-weight: 900;
  letter-spacing: 2.0px;
  text-align: center;    

`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  //max-width: 800px;
  margin: auto;
  margin-top: -48px;
`;

const HomeTitle = styled.h2`
  font-family: 'Helvetica', sans-serif;
  font-size: 32px;
  letter-spacing: 2px;
  font-weight: bold;
  margin: 64px auto 32px auto;
  text-transform: uppercase;
  width: 80%;
  text-align: center;
  @media screen and (max-width: 600px) {
    font-size: 22px;
    margin: 32px auto 16px auto;
    width: 95%;
  }
`;

const Button = ({ href, children }) => {
  if (isNil(href)) return null;
  const to = url.parse(href).pathname;
  return <Link className="uikit-button" to={to}>{children}</Link>;
};


const ImageTop = ({ image, content }) => {
  return (
    <OneImage style={{ backgroundImage: `url(${image.url})` }}>
      <OneImageTitle>
        <Markdown
          components={{
            a: ({ href, children }) => {
              return <Button href={href}>{children}</Button>;
            }
          }}
        >
          {content}
        </Markdown>
      </OneImageTitle>
    </OneImage>
  );
};

const AboutTop = ({ content, image }) => {
  console.log(content);
  return (
    <div className="t-home-row-with-image-on-right" style={{ marginTop: '-120px' }}>
      <div className="t-home-info">
        <Markdown>{content}</Markdown>
      </div>
      <img src={image} />
    </div>
  );
};

const AboutBottom = ({ content }) => (
  <React.Fragment>
    <div className="t-home-info">
      <Markdown>{content}</Markdown>
    </div>
    <ul className="home-info-address">
      <li><FontAwesomeIcon icon={faBookmark} size="2x" /></li>
      <li>Château de Dirac</li>
      <li>Dirac (16410)</li>
      <li>Nouvelle Aquitaine, France</li>
      <li style={{ marginTop: '16px' }}>rendez-nous visite</li>
      <li>sur rendez-vous</li>
      <li style={{ marginTop: '16px' }}>+33 (0)6 08 01 27 79</li>
      <li className="email"><a href="mailto:lpe@lespetitesemplettes.com">lpe@lespetitesemplettes.com</a></li>
      <li><FontAwesomeIcon icon={faBookmark} size="2x" rotation={180} /></li>
    </ul>
  </React.Fragment>
);

const Thumbs = ({ thumbs }) => {
  if (R.isNil(thumbs)) return null;

  return (
    <div>
      <HomeTitle>{thumbs.title}</HomeTitle>
      <RowOfItems items={thumbs.items} />
    </div>
  );
};

const Home = ({
  content
}) => {


  return (
    <HomeContainer>
      <ImageTop content={content.imageTopTitle} image={content.imageTop} />
      <AboutTop content={content.aboutTop} image={'/images/plante2.png'} />
      <Thumbs thumbs={content.thumbs[0]} />
      <Thumbs thumbs={content.thumbs[1]} />
      <Thumbs thumbs={content.thumbs[2]} />
      <Thumbs thumbs={content.thumbs[3]} />
      <AboutBottom content={content.aboutBottom} />
    </HomeContainer>
  );
};

Home.defaultProps = {
  isMobile: true
};

Home.propTypes = {
  isAdmin: PropTypes.bool,
  isMobile: PropTypes.bool
};

export default withIsMobile(Home);
