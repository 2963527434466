import React from 'react';

import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Loading } from '../uikit/UIKit';

import {
  HousingThumb
} from '.';

const HousingsPageQuery = graphql`
  query HousingsPageQuery {
    viewer {
      cmsHousings {
        id
        slug
        title
        picture1 {
          title
          url
        }
      }
    }
  }
`;

const HousingsPage = () => (
  <div className="housings-list">
    <QueryRenderer
      environment={environment}
      query={HousingsPageQuery}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          const housings = props.viewer.cmsHousings;
          return housings.map(housing => <HousingThumb key={housing.id} value={housing}/>);
        }
        return <Loading/>;
      }}
    />
  </div>
);

export default HousingsPage;