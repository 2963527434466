/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplyCouponMutationInput = {|
  code?: ?string,
  clientMutationId?: ?string,
|};
export type ApplyCouponMutationVariables = {|
  input: ApplyCouponMutationInput
|};
export type ApplyCouponMutationResponse = {|
  +applyCouponMutation: ?{|
    +ok: ?boolean,
    +error: ?string,
    +cart: ?{|
      +id: ?string,
      +coupon: ?{|
        +code: ?string,
        +amount: ?number,
      |},
      +total: ?{|
        +totalWithTaxWithoutCoupon: ?number,
        +totalWithTax: ?number,
      |},
    |},
  |}
|};
export type ApplyCouponMutation = {|
  variables: ApplyCouponMutationVariables,
  response: ApplyCouponMutationResponse,
|};
*/


/*
mutation ApplyCouponMutation(
  $input: ApplyCouponMutationInput!
) {
  applyCouponMutation(input: $input) {
    ok
    error
    cart {
      id
      coupon {
        code
        amount
        id
      }
      total {
        totalWithTaxWithoutCoupon
        totalWithTax
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalWithTaxWithoutCoupon",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalWithTax",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplyCouponMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApplyCouponMutationPayload",
        "kind": "LinkedField",
        "name": "applyCouponMutation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Coupon",
                "kind": "LinkedField",
                "name": "coupon",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CartTotal",
                "kind": "LinkedField",
                "name": "total",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApplyCouponMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApplyCouponMutationPayload",
        "kind": "LinkedField",
        "name": "applyCouponMutation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Coupon",
                "kind": "LinkedField",
                "name": "coupon",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CartTotal",
                "kind": "LinkedField",
                "name": "total",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8dcc9d65dfbf265fbea0addee30eea92",
    "id": null,
    "metadata": {},
    "name": "ApplyCouponMutation",
    "operationKind": "mutation",
    "text": "mutation ApplyCouponMutation(\n  $input: ApplyCouponMutationInput!\n) {\n  applyCouponMutation(input: $input) {\n    ok\n    error\n    cart {\n      id\n      coupon {\n        code\n        amount\n        id\n      }\n      total {\n        totalWithTaxWithoutCoupon\n        totalWithTax\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '23bf37e32e45e27c372fc6eda03c13ec';

module.exports = node;
