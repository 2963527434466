import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation UpdateCartItemQtyMutation($input: UpdateCartItemQtyMutationInput!) {
    updateCartItemQtyMutation(input: $input) { 
      ok
      error
      cart {
        id
        items {
          ...CartItemFragment_item
        }
        nbOfItems
        total {
          ...CartTotalFragment_total
        }
      }
    }
  }
`;

export default (productId, delta, done) => {
  const variables = {
    input: {
      productId,
      delta
    }
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (store) => {
      },
      optimisticUpdater: (store) => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};