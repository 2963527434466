/* eslint-disable space-before-function-paren */
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Redirect } from 'react-router-dom';
import { Button } from '../uikit/UIKit';
import OrderListPage from '../orders/OrderListPage';
import SignOutMutation from '../../mutations/SignOutMutation';


const MyAccountPageQuery = graphql`
query MyAccountPageQuery {
  viewer {
    user {
      id
      firstname
      lastname
      email
    }
  }
} 
`;

class MyAccountPage extends Component {
  signOut() {
    SignOutMutation(() => {
    });
  }
  render() {
    return (
      <QueryRenderer
        environment={environment}
        query={MyAccountPageQuery}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            const { user } = props.viewer;
            if (_.isEmpty(user.email)) return <Redirect to="/" />;
            return (
              <React.Fragment>
                <div className="block">
                  <div className="block-title">
                    <h2>Informations sur votre compte</h2>
                  </div>
                  <div className="block-body">
                    {user.firstname}&nbsp;{user.lastname}
                    <br />
                  email: {user.email}
                    <br />
                    <Button
                      style={{ marginTop: 16 }}
                      onClick={() => this.signOut()}>
                      Me déconnecter
                    </Button>
                  </div>
                </div>
                <div className="block">
                  <div className="block-top">
                    <h2>Vos commandes</h2>
                  </div>
                  <div className="block-body">
                    <OrderListPage />
                  </div>
                </div>
              </React.Fragment>
            );
          }
          return null;
        }}
      />
    );
  }
}

export default MyAccountPage;

MyAccountPage.propTypes = {
  user: PropTypes.shape()
};