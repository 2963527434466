const R = require('ramda');
import React from 'react';
import Markdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import ImageGallery from 'react-image-gallery';
import {
  Booking
} from '.';

const Housing = ({ value }) => (
  <div className="housing">
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content={value.meta.description}/>
      <title>{`les petites emplettes - ${value.title}`}</title>
      <link rel="canonical" href="http://mysite.com/example" />
    </Helmet>
    <h2>{value.title}</h2>
    <div className="housing-pictures">
      <div className="housing-pictures-picture">
        <img src={value.picture1.url}/>
      </div>
      <div className="housing-pictures-picture">
        <img src={value.picture2.url}/>
      </div>
    </div>
    <div className="housing-description">
      <Markdown 
        style={{ marginTop: '16px' }}
        source={value.description}
      />    
    </div>
    {value.rooms.map((room, index) => (
      <div key={index}>
        {<h3>{room.title}</h3>}
        <Markdown source={room.description}/> 
        {
          !R.isEmpty(room.gallery) &&
          <div className="housing-room-gallery">
            <ImageGallery 
              items={room.gallery.map(image => ({ original: image.url }))} 
              showNav={false}
              showPlayButton={false}
              showThumbnails={false}
              showFullscreenButton={false}
              showBullets={true}
            />
          </div>
        }
      </div>
    ))}
    <Booking description={value.bookingDescription}/>
  </div>
);

export default Housing;