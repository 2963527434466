import _ from 'lodash';
import React, { Component } from 'react';
import update from 'react-addons-update';
import { Link } from 'react-router-dom';
import { Button, Input } from '../uikit/UIKit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import SignInMutation from '../../mutations/SignInMutation';


class SignIn extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      error: ''
    };
  }

  updateField(fieldname, value) {
    this.setState(update(this.state, {
      [fieldname]: { $set: value }, showMessage: { $set: false },
      error: { $set: '' }
    }));
  }

  submit(e) {
    const { email, password } = this.state;
    e.preventDefault();
    SignInMutation(email, password, (ok, error) => {
      this.setState(update(this.state, { error: { $set: error } }));
    });
  }

  renderMessage() {
    const { error } = this.state;
    return (
      <div>
        {
          !_.isEmpty(error) &&
          <div className="block-error" style={{ marginTop: '32px' }}>
            {SignIn.Messages[error]}
          </div>
        }
      </div>
    );
  }

  render() {
    const { email, password } = this.state;
    return (
      <form className="block" onSubmit={(e) => this.submit(e)}>
        <div className="block-title">
          <h2>{'Me connecter'}</h2>
        </div>
        <div className="block-body block-body-center">
          <Input title="Votre email" type="email" value={email} onChange={(v) => this.updateField('email', v)} />
          <Input title="Votre mot de passe" type="password" value={password} onChange={(v) => this.updateField('password', v)} />

          <br />
          <Button style={{ marginTop: '24px', marginLeft: '16px' }} type="submit">{'Se connecter'}</Button>
          <Link className="block-link" style={{ marginTop: '8px' }} to="/account/signup">{'Je n\'ai pas de compte'}</Link>
          <Link className="block-link" style={{ marginTop: '8px' }} to="/account/lostpassword">{'J\'ai perdu mon mot de passe'}</Link>
          {this.renderMessage()}
        </div>
      </form>
    );
  }
}

SignIn.Messages = {
  'PASSWORD_KO': 'Email ou mot de passe incorrect'
};

export default SignIn;