const R = require('ramda');
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const useSelectCategory = (categories) => {

  const getSubCategories = (categories, index) => {
    return R.compose(
      R.ifElse(
        R.isNil,
        R.always([]),
        R.propOr([], 'children')
      ),
      R.nth(index)
    )(categories);
  };

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [subCategories, setSubCategories] = useState(getSubCategories(categories, 0));

  const showCategory = (index) => {
    setSelectedCategory(
      R.compose(
        R.assoc('index', index),
        R.nth(index)
      )(categories)
    );
    setSubCategories(getSubCategories(categories, index));
  };

  return ({
    selectedCategory,
    setSubCategories,
    showCategory,
    subCategories
  });
};

const MenuProducts = ({
  categories
}) => {
  const {
    selectedCategory,
    subCategories,
    showCategory
  } = useSelectCategory(categories);

  return (
    <div className="menu-products">
      <ul className="menu-products-left">
        {
          categories.map(({ name, url, redirect }, index) => (
            <li 
              key={index}
              onMouseOver={() => showCategory(index)}
              className={classNames('menu-products-left-item', { 'menu-products-left-selected-item': index === selectedCategory.index })}
            >
              <Link to={R.isNil(redirect) || R.isEmpty(redirect) ? `/products/${url}` : redirect}>{name}</Link>
            </li>
          ))
        }
      </ul>
      <ul className="menu-products-right">
        { 
          subCategories.map(({ name, url, redirect }, index) => (
            <li className="menu-products-right-item" key={index}>
              <Link 
                to={R.isNil(redirect) || R.isEmpty(redirect) ? `/products/${selectedCategory.url}/${url}` : redirect}
              >
                {name}
              </Link>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default MenuProducts;