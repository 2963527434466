import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import OrderList from './OrderList';

const OrderListPageQuery = graphql`
  query OrderListPageQuery(
    $count: Int!
    $after: String
    $filter: OrderFilter
  ) {
    viewer {
      ...OrderList_viewer
    }
  } 
`;

class OrderListPage extends Component {
  render () {
    return (
      <QueryRenderer
        environment={environment}
        query={OrderListPageQuery}
        variables={{
          count: 40,
          filter: { },
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return (
              <OrderList
                viewer={props.viewer}
              />
            );
          }
          return <div>Loading</div>;
        }}
      />
    );
  }
}

export default OrderListPage;