/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CartTotalFragment_total$ref: FragmentReference;
declare export opaque type CartTotalFragment_total$fragmentType: CartTotalFragment_total$ref;
export type CartTotalFragment_total = {|
  +id: ?string,
  +country: ?string,
  +productsWithTax: ?number,
  +productsNoTax: ?number,
  +shippingNoTax: ?number,
  +shippingWithTax: ?number,
  +extraShippingWithTax: ?number,
  +extraShippingNoTax: ?number,
  +tax: ?number,
  +totalWithTaxWithoutCoupon: ?number,
  +totalWithTax: ?number,
  +totalNoTax: ?number,
  +mustCheckShippingCost: ?boolean,
  +$refType: CartTotalFragment_total$ref,
|};
export type CartTotalFragment_total$data = CartTotalFragment_total;
export type CartTotalFragment_total$key = {
  +$data?: CartTotalFragment_total$data,
  +$fragmentRefs: CartTotalFragment_total$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartTotalFragment_total",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productsWithTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productsNoTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shippingNoTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shippingWithTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraShippingWithTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraShippingNoTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithTaxWithoutCoupon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalNoTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mustCheckShippingCost",
      "storageKey": null
    }
  ],
  "type": "CartTotal",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'b6e18a7a5a255f0d4232646b8a7cd446';

module.exports = node;
