import React from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';

const RouterLink = (props) => {
  return <Link to={props.href}>{props.children}</Link>;

};

const Description = ({ style, description }) => (
  <div className="block block-large" {...{ style }}>
    <div className="block-title">
      <h2>A propos de ce produit</h2>
    </div>
    <div className="block-body">
      <Markdown 
        source={description}
        renderers={{ linkReference: RouterLink, link: RouterLink }}
      />
    </div>
  </div>
);

Description.propTypes = {
  description: PropTypes.string.isRequired,
  style: stylePropType
};

export default Description;