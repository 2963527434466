/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Address_address$ref = any;
type CartItemFragment_item$ref = any;
type CartTotalFragment_total$ref = any;
export type OrderTunnelPageQueryVariables = {||};
export type OrderTunnelPageQueryResponse = {|
  +viewer: ?{|
    +user: ?{|
      +id: ?string,
      +firstname: ?string,
      +lastname: ?string,
      +email: ?string,
      +addresses: ?{|
        +id: ?string,
        +billingSameAsShipping: ?boolean,
        +shipping: ?{|
          +$fragmentRefs: Address_address$ref
        |},
        +billing: ?{|
          +$fragmentRefs: Address_address$ref
        |},
      |},
    |},
    +cart: ?{|
      +id: ?string,
      +total: ?{|
        +totalWithTax: ?number,
        +$fragmentRefs: CartTotalFragment_total$ref,
      |},
      +items: ?$ReadOnlyArray<?{|
        +$fragmentRefs: CartItemFragment_item$ref
      |}>,
    |},
    +braintreeToken: ?string,
  |}
|};
export type OrderTunnelPageQuery = {|
  variables: OrderTunnelPageQueryVariables,
  response: OrderTunnelPageQueryResponse,
|};
*/


/*
query OrderTunnelPageQuery {
  viewer {
    user {
      id
      firstname
      lastname
      email
      addresses {
        id
        billingSameAsShipping
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
      }
    }
    cart {
      id
      total {
        ...CartTotalFragment_total
        totalWithTax
        id
      }
      items {
        ...CartItemFragment_item
      }
    }
    braintreeToken
    id
  }
}

fragment Address_address on Address {
  firstname
  lastname
  companyName
  phone
  field1
  field2
  field3
  city
  postcode
  country
  extra
  active
}

fragment CartItemFragment_item on CartItem {
  id
  name
  price
  qty
  option {
    title
    value
  }
}

fragment CartTotalFragment_total on CartTotal {
  id
  country
  productsWithTax
  productsNoTax
  shippingNoTax
  shippingWithTax
  extraShippingWithTax
  extraShippingNoTax
  tax
  totalWithTaxWithoutCoupon
  totalWithTax
  totalNoTax
  mustCheckShippingCost
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingSameAsShipping",
  "storageKey": null
},
v5 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "Address_address"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalWithTax",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "braintreeToken",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v9 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "companyName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field1",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field2",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field3",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "city",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postcode",
    "storageKey": null
  },
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "extra",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "active",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderTunnelPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Addresses",
                "kind": "LinkedField",
                "name": "addresses",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "shipping",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "billing",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartTotal",
                "kind": "LinkedField",
                "name": "total",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CartTotalFragment_total"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CartItem",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CartItemFragment_item"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrderTunnelPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Addresses",
                "kind": "LinkedField",
                "name": "addresses",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "shipping",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "billing",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartTotal",
                "kind": "LinkedField",
                "name": "total",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productsWithTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productsNoTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shippingNoTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shippingWithTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "extraShippingWithTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "extraShippingNoTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalWithTaxWithoutCoupon",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalNoTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mustCheckShippingCost",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CartItem",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "qty",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductOptionInCart",
                    "kind": "LinkedField",
                    "name": "option",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cdb560e36f93ae0b555bfefc1eda5f59",
    "id": null,
    "metadata": {},
    "name": "OrderTunnelPageQuery",
    "operationKind": "query",
    "text": "query OrderTunnelPageQuery {\n  viewer {\n    user {\n      id\n      firstname\n      lastname\n      email\n      addresses {\n        id\n        billingSameAsShipping\n        shipping {\n          ...Address_address\n        }\n        billing {\n          ...Address_address\n        }\n      }\n    }\n    cart {\n      id\n      total {\n        ...CartTotalFragment_total\n        totalWithTax\n        id\n      }\n      items {\n        ...CartItemFragment_item\n      }\n    }\n    braintreeToken\n    id\n  }\n}\n\nfragment Address_address on Address {\n  firstname\n  lastname\n  companyName\n  phone\n  field1\n  field2\n  field3\n  city\n  postcode\n  country\n  extra\n  active\n}\n\nfragment CartItemFragment_item on CartItem {\n  id\n  name\n  price\n  qty\n  option {\n    title\n    value\n  }\n}\n\nfragment CartTotalFragment_total on CartTotal {\n  id\n  country\n  productsWithTax\n  productsNoTax\n  shippingNoTax\n  shippingWithTax\n  extraShippingWithTax\n  extraShippingNoTax\n  tax\n  totalWithTaxWithoutCoupon\n  totalWithTax\n  totalNoTax\n  mustCheckShippingCost\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '73df8f0d07a3a9df5bf8cbc75c4d166d';

module.exports = node;
