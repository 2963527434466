import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation UpdateCategoryMutation($input: UpdateCategoryMutationInput!) {
  updateCategoryMutation(input: $input) { 
    ok
    error
  }
}
`;

export default (id, { description }, done) => {
  const variables = {
    input: {
      id,
      description
    }
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};