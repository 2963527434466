import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Link } from 'react-router-dom';
import BlogArticle from './BlogArticle';
import { Loading } from '../uikit/UIKit';

const ArticleQuery = graphql`
  query BlogPageQuery($id: ID) {
    viewer {
      cmsArticle(id: $id) {
        id
        title
        blocks {
          text
          gallery {
            title
            url
          }
        }
      }
    }
  }
`;

const BlogPage = (props) => (
  <div className="blog-article">
    <Link to="/blog">{'liste des articles'}</Link>
    <QueryRenderer
      environment={environment}
      query={ArticleQuery}
      variables={{
        id: props.articleId
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          return <BlogArticle
            title={props.viewer.cmsArticle.title}
            blocks={props.viewer.cmsArticle.blocks}
          />;
        }
        return <Loading/>;
      }}
    />
  </div>
);

export default BlogPage;