import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation RemoveCartItemMutation($input: RemoveCartItemMutationInput!) {
    removeCartItemMutation(input: $input) { 
      ok
      error
      cart {
        id
        items {
          ...CartItemFragment_item
        }
        nbOfItems
        total {
          ...CartTotalFragment_total
        }
      }
    }
  }
`;

export default (productId) => {
  const variables = {
    input: {
      productId
    }
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: store => {
      },
      optimisticUpdater: store => {
      },
      onCompleted: () => {
      },
      onError: err => console.error(err),
    }
  );
};