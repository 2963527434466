import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation LostPasswordMutation($input: LostPasswordMutationInput!) {
  lostPasswordMutation(input: $input) {
    ok
    error
  }
}
`;

export default (email, done) => {
  const variables = {
    input: {
      email
    },
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.lostPasswordMutation;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
