import './Product.less';

const R = require('ramda');
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Redirect
} from 'react-router-dom';

import Description from './Description';
import Pictures from './pictures/Pictures';
import Price from './Price';
import Stock from './Stock';
import Options from './variation/Options';
import Qty from './Qty';
import AddToCart from './AddToCart';

import { Back } from '../uikit/UIKit';

import AddToCartMutation from '../../mutations/AddToCartMutation';

const Product = ({
  product
}) => {

  const [item, setItem] = useState({
    optionKey: '',
    price: product.price,
    stock: product.stock,
    qty: 1,
  });
  
  const [addingToCart, setAddingToCart] = useState(false);

  /*
    super(props);
    this.state = {
      optionKey: '',
      price: props.product.price,
      stock: props.product.stock,
      qty: 1,
      addingToCart: false
    };
  }
  */

  const onUpdateOptionAttributes = ({ optionKey, price, stock }) => {
    setItem(
      R.compose(
        R.assoc('optionKey', optionKey),
        R.assoc('price', price),
        R.assoc('stock', stock),
      )(item)
    );
  };

  const onUpdateQty = (qty) => {
    setItem(
      R.assoc('qty', qty)(item)
    );
  };

  const onAddToCart = () => {
    const { id } = product;
    const { optionKey, price, qty } = item;
    setAddingToCart(true);
    AddToCartMutation(id, price, qty, optionKey, () => {
      setTimeout(() => {
        setAddingToCart(false);
      }, 300);
    });
  };

  const { price, stock, qty } = item;
  const { name, description, pictures, variation } = product;

  if (!R.isNil(product.redirect) && !R.isEmpty(product.redirect)) return <Redirect to={product.redirect}/>;
  return (
    <div>      
      <div className="block block-large block-row">
        <div className="product-block-left">
          <Pictures pictures={pictures}/>
        </div>
        <div className="block product-block-right">
          <div className="block-title">
            <h2>{name}</h2>
          </div>
          <div className="block-body block-body-center">
            <Price price={price}/>
            <Options 
              variation={variation} 
              updateOptionAttributes={onUpdateOptionAttributes}
            />
            <div style={{ height: '40px' }}/>
            <Qty 
              qty={qty} 
              stock={stock}
              updateQty={onUpdateQty}
            />
            <Stock stock={stock}/>
          </div>
          <AddToCart
            style={{ marginTop: '16px' }}
            disabled={stock === 0 || qty === 0}
            addingToCart={addingToCart}
            addToCart={onAddToCart}
          />
        </div>
      </div>
      <Description style={{ marginTop: '42px' }} description={description}/>
      <Back/>
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
    name: PropTypes.string,
    redirect: PropTypes.string,
    price: PropTypes.number,
    stock: PropTypes.number,
    pictures: PropTypes.arrayOf(
      PropTypes.shape({
        filename: PropTypes.string
      })
    ),
    variation: PropTypes.shape({
      name: PropTypes.string,
      default: PropTypes.string
    })
  })
};
export default Product;