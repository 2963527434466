import './Input.less';
import React, { Component } from 'react';
import PropTypes from 'prop-types';



class Input extends Component {
  constructor (props) {
    super(props);
    this.state = {
      value: ''
    };
  }

  render () {
    const { placeholder, title, type, value, onChange } = this.props;
    return (
      <div className="uikit-input">
        {title !== '' && <div>{title}</div>}
        <input placeholder={placeholder} value={value} type={type} onChange={(e) => onChange(e.target.value)}/>
      </div>
    );
  }
}

Input.defaultProps = {
  value: '',
  title: '',
  type: 'text',
  placeholder: '',
  onChange: () => {}
};

Input.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func
};

export default Input;