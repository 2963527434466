import qs from 'query-string';

import React from 'react';
import {
  Redirect,
  Switch,
  Route,
  BrowserRouter as Router
} from 'react-router-dom';

import Template from './containers/template/Template';
import BlogTemplate from './containers/template/blog/Template';
import HousingTemplate from './containers/template/housing/Template';
import HomePage from './containers/home/HomePage';

import ProductListPage from './containers/products/ProductListPage';

import ProductPage from './containers/product/ProductPage';
import Account from './containers/account/Account';
import CartPage from './containers/cart/CartPage';
import Cgv from './containers/misc/Cgv';
import OrderTunnelPage from './containers/orderTunnel/OrderTunnelPage';
import OrderPaymentError from './containers/orderTunnel/OrderPaymentError';
import OrderSuccess from './containers/orderTunnel/OrderSuccess';
import MyAccountPage from './containers/myAccount/MyAccountPage';
import Orders from './containers/orders/Orders';
import Blog from './containers/blog/Blog';
import ModalRouter from './ModalRouter';
import GoogleAnalytics from './GoogleAnalytics';
import { Housings } from './containers/housings';
import { RelayEnvironmentProvider } from 'react-relay';

import Environment from './Environment'

let Routes = () => (
  <RelayEnvironmentProvider environment={Environment}>
    <Router>
      <GoogleAnalytics>
        <ModalRouter />
        <Switch>
          <Route path="/blog">
            <BlogTemplate>
              <Switch>
                <Route path="/blog" component={Blog} />
              </Switch>
            </BlogTemplate>
          </Route>
          <Route path="/housings">
            <HousingTemplate>
              <Switch>
                <Route path="/housings" component={Housings} />
              </Switch>
            </HousingTemplate>
          </Route>
          <Route path="/">
            <Template>
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/cgv" component={Cgv} />
                <Route path="/account" component={Account} />
                <Route path="/products/*" component={ProductListPage} />
                <Route path="/product/:slug" exact component={ProductPage} />
                <Route path="/cart" component={CartPage} />
                <Route exact path="/order" component={OrderTunnelPage} />
                <Route exact path="/order/success" component={OrderSuccess} />
                <Route exact path="/order/paymenterror" component={OrderPaymentError} />
                <Route exact path="/myaccount" component={MyAccountPage} />
                <Route path="/myaccount/orders" component={Orders} />
                <Redirect to="/" />
              </Switch>
            </Template>
          </Route>
        </Switch>
      </GoogleAnalytics>
    </Router>
  </RelayEnvironmentProvider>
);

export default Routes;