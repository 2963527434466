/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderItemFragment_item$ref: FragmentReference;
declare export opaque type OrderItemFragment_item$fragmentType: OrderItemFragment_item$ref;
export type OrderItemFragment_item = {|
  +id: ?string,
  +name: ?string,
  +qty: ?number,
  +optionKey: ?string,
  +price: ?number,
  +shippingCost: ?number,
  +option: ?{|
    +name: ?string,
    +value: ?{|
      +name: ?string
    |},
  |},
  +$refType: OrderItemFragment_item$ref,
|};
export type OrderItemFragment_item$data = OrderItemFragment_item;
export type OrderItemFragment_item$key = {
  +$data?: OrderItemFragment_item$data,
  +$fragmentRefs: OrderItemFragment_item$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderItemFragment_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qty",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optionKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shippingCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderItemOption",
      "kind": "LinkedField",
      "name": "option",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderItemOptionValue",
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderItem",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd9ce2eaa529c8bac9dbcfad5a8423f75';

module.exports = node;
