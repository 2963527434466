import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import accounting from 'accounting';
import Format from '../../helpers/Format';
import { graphql, createFragmentContainer } from 'react-relay';

const S_OrderStatus = styled.div`
  .order-state {
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: space-between;    
  }
  .order-status-state {
    font-size: 14px;
  }
  .order-status-date {
    font-size: 12px;
  }
`;

const OrderStatus = ({ status }) => {

  return (
    <S_OrderStatus>
      {status.history.map(({ state, date }, index) => (
        <div className="order-state" key={index}>
          <div className="order-status-state">
            {OrderStatus.states[state].fr}
          </div>
          <div className="order-status-date">
            {Format.DateToString(date)}
          </div>
        </div>
      )).reverse()}
    </S_OrderStatus>  
  );
};

OrderStatus.states = {
  new: {
    fr: 'Nouvelle',
    en: 'New'
  },
  cancelled: {
    fr: 'Annulée',
    en: 'Cancelled'
  },
  packing: {
    fr: 'En préparation',
    en: 'Packing'
  },
  on_its_way: {
    fr: 'En chemin',
    en: 'On its way'
  },
  closed: {
    fr: 'Fermée',
    en: 'Closed'
  }

};

OrderStatus.propTypes = {
  status: PropTypes.shape({
    state: PropTypes.oneOf(['new', 'cancelled', 'on_its_way', 'packing', 'closed']),
    history: PropTypes.arrayOf(
      PropTypes.shape({
        state: PropTypes.oneOf(['new', 'cancelled', 'on_its_way', 'packing', 'closed']),
        date: PropTypes.string
      })
    )
  })
};

export default createFragmentContainer(
  OrderStatus, 
  {
    status: graphql`
      fragment OrderStatus_status on OrderStatus {
        state
        history {
          state
          date
        }
      }
    `
  }
);