import './Top.less';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CartTop from '../cart/CartTop';
import MyAccountTop from '../myAccount/MyAccountTop';
import Menu from '../menu/Menu';

const Top = ({ isMobile, toggleShowMenu }) => {
  return (
    <div className="top">
      <div className="top-main">
        <div className="top-main-l1">
          <div className="brand">
            <img src="/images/logo.png"/>
            <Link to="/"><h1>les petites emplettes</h1></Link>
          </div>
          <div style={{ width: '347px' }} className="block-right">
            <CartTop/>
            <MyAccountTop/>
          </div>
        </div>
        <div className="top-main-l2">
          <Menu/>
        </div>
      </div>
    </div>
  );
};

Top.defaultProps = {
  isMobile: false
};

Top.PropTypes = {
  isMobile: PropTypes.boolean
};
export default Top;