import _ from 'lodash';
import React, { Component } from 'react';
import update from 'react-addons-update';
import styled from 'styled-components';
import S from 'string';
import { Button, Input } from '../../uikit/UIKit';

import SignUpUserMutation from '../../../mutations/SignUpMutation';

const S_SignUp = styled.form`
  flex: 1;
`;

class SignUp extends Component {
  constructor () {
    super();
    this.state = {
      email: '',
      firstname: '',
      lastname: '',
      password1: '',
      password2: '',
      error: ''      
    };
  }
  enableSubmit () {
    const { password1, password2, firstname, lastname } = this.state;
    return ( 
      S(password1).length > 6 &&
      S(password2).length > 6 &&
      password1 === password2 &&
      S(firstname).length > 3 &&
      S(lastname).length > 3
    );

  }

  updateField (fieldname, value) {
    this.setState(update(this.state, { 
      [fieldname]: { $set: value },
      error: { $set: '' }
    }));
  }

  submit (e) {
    const { email, firstname, lastname, password1 } = this.state;
    e.preventDefault();
    SignUpUserMutation(email, firstname, lastname, password1, (ok, error) => {
      this.setState(update(this.state, { responseCode: { $set: error } }));
    });
  }

  renderMessage () {
    const { error } = this.state;
    return (
      <div>
        {
          !_.isEmpty(error) &&
          <div className="block-error">
            {SignUp.Messages[error]}
          </div>
        }
      </div>
    );
  }

  render () {
    const { email, firstname, lastname, password1, password2 } = this.state;
    return (
      <S_SignUp onSubmit={(e) => this.submit(e)}>
        <h3>{'Je n\'ai pas de compte'}</h3>
        <Input title="Votre email" value={email} onChange={(v) => this.updateField('email', v)}/>
        <Input title="Votre prénom" value={firstname} onChange={(v) => this.updateField('firstname', v)}/>
        <Input title="Votre nom" value={lastname} onChange={(v) => this.updateField('lastname', v)}/>
        <Input type="password" title="Votre mot de passe" value={password1} onChange={(v) => this.updateField('password1', v)}/>
        <Input type="password" title="Votre mot de passe (une deuxième fois)" value={password2} onChange={(v) => this.updateField('password2', v)}/>
        <Button type="submit" style={{ marginTop: '16px' }} small={true} disabled={!this.enableSubmit()}>{'S\'enregistrer'}</Button>
        {this.renderMessage()}
      </S_SignUp>
    );
  }
}

SignUp.Messages = {
  'USER_DOES_EXIST': 'Un utilisateur est déjà enregistré avec cette adresse email'
};

export default SignUp;