import './MyAccountTop.less';
import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';


const MyAccountTopQuery = graphql`
query MyAccountTopQuery {
  viewer {
    user {
      id
      email
      firstname
    }
  }
} 
`;

class MyAccountTop extends Component {
  render () {
    return (
      <QueryRenderer
        environment={environment}
        query={MyAccountTopQuery}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            const { user } = props.viewer;
            return (
              <div className="my-account-top">
                {
                  !_.isEmpty(user.email)
                    ? <Link to="/myaccount" className="top-username">{user.firstname}</Link> 
                    : <Link to="/account/signin" className="top-username">Connexion</Link> 
                }
              </div>
            );
          }
          return null;
        }}
      />
    );
  }
}

export default MyAccountTop;