import _ from 'lodash';
import React, { Component } from 'react';
import update from 'react-addons-update';
import { Link } from 'react-router-dom';
import S from 'string';
import { Button, Input } from '../uikit/UIKit';

import SignUpMutation from '../../mutations/SignUpMutation';

class SignUp extends Component {
  constructor () {
    super();
    this.state = {
      email: '',
      firstname: '',
      lastname: '',
      password1: '',
      password2: '',
      error: ''      
    };
  }
  enableSubmit () {
    const { email, password1, password2, firstname, lastname } = this.state;
    return ( 
      S(password1).length > 6 &&
      S(password2).length > 6 &&
      password1 === password2 &&
      S(firstname).length >= 3 &&
      S(lastname).length >= 3
    );

  }

  updateField (fieldname, value) {
    this.setState(update(this.state, { 
      [fieldname]: { $set: value },
      error: { $set: '' }
    }));
  }

  submit (e) {
    const { email, firstname, lastname, password1 } = this.state;
    e.preventDefault();
    SignUpMutation(email, firstname, lastname, password1, (ok, error) => {
      this.setState(update(this.state, { error: { $set: error } }));
    });
  }

  renderMessage () {
    const { error } = this.state;
    return (
      <div>
        {
          !_.isEmpty(error) &&
          <div className="block-error">
            {SignUp.Messages[error]}
          </div>
        }
      </div>
    );
  }

  render () {
    const { email, firstname, lastname, password1, password2 } = this.state;
    return (
      <form className="block" onSubmit={(e) => this.submit(e)}>
        <div className="block-title">
          <h2>Créer mon compte</h2>
        </div>
        <div className="block-body block-body-center">
          <Input title="Votre email" value={email} onChange={(v) => this.updateField('email', v)}/>
          <Input title="Votre prénom" value={firstname} onChange={(v) => this.updateField('firstname', v)}/>
          <Input title="Votre nom" value={lastname} onChange={(v) => this.updateField('lastname', v)}/>
          <Input type="password" title="Votre mot de passe" value={password1} onChange={(v) => this.updateField('password1', v)}/>
          <Input type="password" title="Votre mot de passe (une deuxième fois)" value={password2} onChange={(v) => this.updateField('password2', v)}/>
          <br/>
          <Button type="submit" style={{ marginTop: '24px', marginBottom: '16px' }} disabled={!this.enableSubmit()}>{'S\'enregistrer'}</Button>
          <Link to="/account/signin" style={{ marginTop: '8px' }} className="block-link">{'J\'ai déjà un compte'}</Link>
          {this.renderMessage()}
        </div>
      </form>
    );
  }
}

SignUp.Messages = {
  'USER_DOES_EXIST': 'Un utilisateur est déjà enregistré avec cette adresse email'
};

export default SignUp;