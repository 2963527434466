import './More.less';

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';

import {
  branch,
  renderNothing
} from 'recompose';


const More = branch(
  ({ hasMore }) => !hasMore,
  renderNothing
)(
  ({ loading, onLoadMore }) => (
    <div className="product-list-more">
      {loading && <span>...</span>}
      {!loading && <div onClick={onLoadMore}><FontAwesomeIcon icon={faPlusCircle} size="2x" /></div>}
    </div>
  )
);

export default More;