import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isAdmin } from '../../libs';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Loading } from '../uikit/UIKit';

import Home from './Home';

const HomePageQuery = graphql`
query HomePageQuery {
  viewer {
    user {
      email
      firstname
    }
    home {
      imageTop {
        url
        title
      }
      imageTopTitle
      aboutTop
      aboutBottom
      thumbs {
        id
        title
        items {
          title
          description
          image {
            url
            title
          }
          link
        }
      }
    }
  }
} 
`;

const HomePage = () => (
  <QueryRenderer
    environment={environment}
    query={HomePageQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.message}</div>;
      } else if (props) {
        return (
          <div className="query-product-list-page">
            <Home
              content={props.viewer.home}
            />
          </div>
        );
      }
      return <Loading />;
    }}
  />
);

export default HomePage;