import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import Format from '../../helpers/Format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import URL from 'url';

const S_OrderThumb = styled.div`
  display: flex;
  overflow: hidden;
  width: 600px;
  div {
    display: flex;
    align-items: center;
    //justify-content: center;
    flex: 1;
  }
`;

class OrderThumb extends Component {

  render() {
    const { id, date, orderRef, total, status } = this.props.order;
    return (
      <S_OrderThumb to={`/orders/${id}`}>
        <div>{orderRef}</div>
        <div>{Format.DateToString(date)}</div>
        <div>{status.state}</div>
        <div>{Format.PriceToString(total.totalWithTax)}</div>
        <div><Link to={`/myaccount/orders/${orderRef}`}><FontAwesomeIcon icon={faEye} /></Link></div>
      </S_OrderThumb>
    );
  }
}

OrderThumb.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    date: PropTypes.string,
    orderRef: PropTypes.string,
    status: PropTypes.string,
    total: PropTypes.shape({
      totalWithTax: PropTypes.number
    })
  })
};

export default createFragmentContainer(
  OrderThumb,
  {
    order: graphql`
    fragment OrderThumb_order on Order {
      id,
      orderRef
      date
      status {
        state
      }
      total {
        totalWithTax
      }
    }
  `
  });