import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'react-addons-update';
import styled from 'styled-components';
import { graphql, createFragmentContainer } from 'react-relay';
import CountryList from './CountryList';
import EditAddress from './EditAddress';

const S_Address = styled.div`
  .address-top {
    display: flex;
    align-items: center;
  }
`;

class Address extends Component {
  
  constructor (props) {
    super(props);

    this.state = {
      edit: _.isNull(props.address),
      create: _.isNull(props.address)
    };
  }

  componentWillReceiveProps (nextProps) {
    if (!_.isNull(this.props.address) && nextProps.address.country !== this.props.address.country) {
      if (!_.isUndefined(this.props.onCountryChange
      )) this.props.onCountryChange(nextProps.address.country);
    }
  }

  setEdit (value) {
    this.setState(update(this.state, { edit: { $set: value } }));
  }

  render () {
    const { edit, create } = this.state;
    let { type, address } = this.props;
    address = _.isNull(address) ? {} : address;
    if (edit) {
      return (
        <S_Address>
          <EditAddress {...{ type, create, address }} onClose={() => this.setEdit(false)}/>
        </S_Address>
      );
    }
    const { firstname, lastname, companyName, phone, field1, field2, field3, city, postcode, country } = address;
    
    return (
      <S_Address>
        <div className="address-top">
          <a href="javascript:void(0);" className="block-action" onClick={() => this.setEdit(true)}>editer</a>
        </div>
        {firstname} {lastname}
        {companyName !== '' && <div>{companyName}</div>}
        {phone}
        {field1 !== '' && <div>{field1}</div>}
        {field2 !== '' && <div>{field2}</div>}
        {field3 !== '' && <div>{field3}</div>}
        <div>{postcode} &nbsp;{city}</div>
        <div>{CountryList.getTitle(country, 'fr')}</div>
      </S_Address>
    );

  }
}

export default createFragmentContainer(
  Address, 
  {
    address: graphql`
      fragment Address_address on Address {
        firstname
        lastname
        companyName
        phone
        field1
        field2
        field3
        city
        postcode
        country
        extra
        active
      }
    `
  }
);

Address.propTypes = {
  address: PropTypes.shape({
    country: PropTypes.string
  }),
  onCountryChange: PropTypes.func
};
