import './ProductMobile.less';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Description from './Description';
import PicturesMobile from './pictures/PicturesMobile';
import Price from './Price';
import Stock from './Stock';
import Options from './variation/Options';
import Qty from './Qty';
import AddToCart from './AddToCart';

import AddToCartMutation from '../../mutations/AddToCartMutation';

import { Back } from '../uikit/UIKit';

class Product extends Component {
  constructor (props) {
    super(props);
    this.state = {
      optionKey: '',
      price: props.product.price,
      stock: props.product.stock,
      qty: 1,
      addingToCart: false
    };
  }

  updateOptionAttributes (attributes) {
    this.setState(
      update(
        this.state,
        { $set: 
          { 
            optionKey: attributes.optionKey,
            price: attributes.price,
            stock: attributes.stock
          } 
        }
      )
    );
  }

  updateQty (qty) {
    this.setState(update(this.state, { $set: { qty } }));
  }

  addToCart () {
    const { id } = this.props.product;
    const { optionKey, price, qty } = this.state;
    this.setState({ ...this.state, addingToCart: true });
    AddToCartMutation(id, price, qty, optionKey, () => {
      setTimeout(() => {
        this.setState({ ...this.state, addingToCart: false });
      }, 300);
    });
  }

  render () {
    const { price, stock, qty } = this.state;
    const { name, description, pictures, variation } = this.props.product;

    return (
      <div className="product-mobile">      
        <div>
          <PicturesMobile pictures={pictures}/>
        </div>
        <div>
          <h1>{name}</h1>
          <div className="block">
            <Price price={price}/>
            <Options 
              variation={variation} 
              updateOptionAttributes={attributes => this.updateOptionAttributes(attributes)}
            />
            <div style={{ height: '32px' }}/>
            <Qty 
              qty={qty} 
              stock={stock}
              updateQty={qty => this.updateQty(qty)}
            />
            <Stock stock={stock}/>
          </div>
          <AddToCart
            style={{ marginTop: '16px' }}
            disabled={stock === 0 || qty === 0}
            addingToCart={this.state.addingToCart}
            addToCart={() => this.addToCart()}
          />
        </div>
        <Description description={description}/>
        <Back/>
      </div>
    );
  }
}

Product.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    stock: PropTypes.number,
    pictures: PropTypes.arrayOf(
      PropTypes.shape({
        filename: PropTypes.string
      })
    ),
    variation: PropTypes.shape({
      name: PropTypes.string,
      default: PropTypes.string
    })
  })
};
export default Product;