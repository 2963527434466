import './CartTop.less';
import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import accounting from 'accounting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';

const CartTopQuery = graphql`
  query CartTopQuery {
    viewer {
      cart {
        id
        nbOfItems
        total {
          id
          productsWithTax
        }
      }
    }
  } 
`;

const Container = styled(Link)`
  
`;
class CartTop extends Component {
  render() {
    return (
      <Container to={'/cart'}>
        <QueryRenderer
          environment={environment}
          query={CartTopQuery}
          render={({ error, props }) => {
            if (error) {
              return <div>{error.message}</div>;
            } else if (props) {
              return (
                <div className="cart-top">
                  <FontAwesomeIcon icon={faShoppingCart} />
                  <Link className="top-cart-amount" to={'/cart'}>
                    {accounting.formatMoney(props.viewer.cart.total.productsWithTax, '€', 2, ' ', ',', '%v %s')}
                  </Link>
                </div>
              );
            }
            return <div>Loading</div>;
          }}
        />
      </Container>
    );
  }
}

export default CartTop;