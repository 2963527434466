import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import Select from 'react-select';

class Options extends Component {

  constructor (props) {
    super(props);

    this.state = {
      selectedOptionKey: props.variation.default
    };
  }

  componentDidMount () {
    this.updateOptionAttributes();
  }

  hasOptions () {
    const { variation } = this.props;
    const { options } = variation;

    return (options.length > 0 && variation.name !== ''); 
  }

  updateOptionAttributes (selectedOptionKey = this.state.selectedOptionKey) {
    if (this.hasOptions()) {
      const option = _.find(this.props.variation.options, (option) => {
        return option.key === selectedOptionKey;
      });
      this.props.updateOptionAttributes({
        optionKey: selectedOptionKey,
        price: option.price,
        stock: option.stock
      });
    }
  }

  onSelectOption (optionKey) {
    this.setState(update(this.state, { $set: { selectedOptionKey: optionKey } }));
    this.updateOptionAttributes(optionKey);
  }

  prepareOptions () {
    const { name, options } = this.props.variation;
    
    return options.map((option) => ({
      value: option.key,
      label: `${name} : ${option.name}`
    }));
  }

  render () {
    const { selectedOptionKey } = this.state;
    const { name } = this.props.variation;
    if (!this.hasOptions()) return null;

    return (
      <div className="product-options">
        <Select
          clearable={false}
          placeholder={name}
          searchable={false}
          style={{ width: '350px', textAlign: 'center' }}
          options={this.prepareOptions()}
          value={selectedOptionKey}
          simpleValue={true}
          onChange={optionKey => this.onSelectOption(optionKey)}
        />
      </div>
    );
  }
}

Options.propTypes = {
  variation: PropTypes.shape({
    name: PropTypes.string,
    default: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.number,
        stock: PropTypes.number,
        shippingCost: PropTypes.number
      })
    )
  }),
  updateOptionAttributes: PropTypes.func.isRequired,
};

export default Options;