import './L1.less';
const R = require('ramda');
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'; 
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom'; 
import Markdown from 'react-markdown';

const RouterLink = (props) => {
  return <Link to={props.href} className="l1-description-link">{props.children}</Link>;
};

const useMenu = (location) => {
  const getPathname = R.prop('pathname');

  const [show, setShow] = useState(false);
  const pathname = useRef(getPathname(location));

  useEffect(() => {
    if (pathname.current !== getPathname(location)) {
      pathname.current = getPathname(location); 
      setShow(false);
    }
  }, [location]);

  const hideMenu = () => {
    setShow(false);
  };

  const showMenu = () => {
    setShow(true);
  };

  return ({
    show,
    hideMenu,
    showMenu
  });
};

const L1 = ({
  location,
  children,
  to,
  title,
  description,
  pos 
}) => {
  const {
    show,
    hideMenu,
    showMenu
  } = useMenu(location);

  const getMenuLeftPosition = () => {
    return Math.max(0, (window.innerWidth - 1280) / 2);
  };

  const getMenuWidth = () => {
    return Math.min(1280, window.innerWidth);
  };

  return (
    <div
      className="l1"
      onMouseOut={hideMenu}
      onMouseOver={showMenu}
    >
      <div
        style={{ left: `${pos * 100}px` }}              
        className={classNames('l1-title', { 'l1-title-selected': show })}
      >
        {R.isEmpty(to) && <span>{title}</span>}
        {!R.isEmpty(to) && <Link {...{ to }}>{title}</Link>}
      </div>
      <div
        id={pos}
        className="junction"
        style={{ display: show ? 'flex' : 'none' }}    
        onMouseOut={hideMenu}
      />
      <div 
        id={pos}
        className="l1-items"
        style={{ 
          display: show ? 'flex' : 'none',        
          left: getMenuLeftPosition(),
          width: getMenuWidth()
        }}
        onMouseOut={hideMenu}
      >
        <div>
          <div className="l1-description">
            <Markdown 
              source={description}
              renderers={{ linkReference: RouterLink, link: RouterLink }}
            />
          </div>
          <div className="l1-items-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

L1.menuWidth = window.innerWidth * 0.2;

L1.defaultProps = {
  title: '!undefined!',
  to: ''
};

L1.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string
};

export default withRouter(L1);