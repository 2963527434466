import './Footer.less';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';


const Footer = () => (
  <div className="footer">
    <div className="footer-body">
      <ul>
        {Footer.blocks.map((block, index) => { return (<li key={index}>{block.title}</li>); })}
      </ul>
    </div>
    <div className="footer-last-line">
      <div>
        les petites emplettes - fournitures générales pour toute la famille
        &nbsp;&nbsp;&nbsp;{'//'}&nbsp;&nbsp;&nbsp;
        <a href="/cgv">cgv</a>
      </div>
      <div>
        <a href="https://www.braintreepayments.com" ><img className="t-footer-img" src="/images/braintree-logo.png" /></a>
        <a href="https://www.colissimo.entreprise.laposte.fr/fr"><img className="t-footer-img" src="/images/logoColissimo.png" /></a>
        <a target="_blank" rel="noopener noreferrer" href="http://www.instagram.com/lespetitesemplettes"><FontAwesomeIcon icon={faInstagram} size="2x" /></a>
      </div>
    </div>
  </div>
);

Footer.blocks = [];

export default Footer;
