import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Title from './components/Title';

import ShippingAddress from './addresses/ShippingAddress';
import BillingAddress from './addresses/BillingAddress';

class OrderAddresses extends Component {
  
  render () {
    const { addresses } = this.props;
    return (
      <div className="block">
        <div className="block-top">
          <Title icon="truck" value="Mes adresses"/>
        </div>
        <div className="block-body">
          <ShippingAddress address={addresses.shipping} onCountryChange={(country) => this.props.onShippingCountryChange(country)}/>
          {!_.isNull(addresses.shipping) && <BillingAddress address={addresses.billing} billingSameAsShipping={addresses.billingSameAsShipping}/>}
        </div>
      </div>
    );
  }
}

OrderAddresses.propTypes = {
  onShippingCountryChange: PropTypes.func
};

export default OrderAddresses;
