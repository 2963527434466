/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderTotal_total$ref: FragmentReference;
declare export opaque type OrderTotal_total$fragmentType: OrderTotal_total$ref;
export type OrderTotal_total = {|
  +country: ?string,
  +productsWithTax: ?number,
  +productsNoTax: ?number,
  +shippingNoTax: ?number,
  +shippingWithTax: ?number,
  +extraShippingWithTax: ?number,
  +extraShippingNoTax: ?number,
  +tax: ?number,
  +totalWithTax: ?number,
  +totalNoTax: ?number,
  +mustCheckShippingCost: ?boolean,
  +totalWithTaxWithoutCoupon: ?number,
  +$refType: OrderTotal_total$ref,
|};
export type OrderTotal_total$data = OrderTotal_total;
export type OrderTotal_total$key = {
  +$data?: OrderTotal_total$data,
  +$fragmentRefs: OrderTotal_total$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderTotal_total",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productsWithTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productsNoTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shippingNoTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shippingWithTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraShippingWithTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraShippingNoTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalNoTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mustCheckShippingCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithTaxWithoutCoupon",
      "storageKey": null
    }
  ],
  "type": "OrderTotal",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7c3ce757f112e8cda56b4e051d2871a8';

module.exports = node;
