/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Address_address$ref = any;
export type AddressTypeEnum = "billing" | "shipping" | "%future added value";
export type UpdateAddressMutationInput = {|
  type?: ?AddressTypeEnum,
  firstname?: ?string,
  lastname?: ?string,
  companyName?: ?string,
  phone?: ?string,
  field1?: ?string,
  field2?: ?string,
  field3?: ?string,
  city?: ?string,
  postcode?: ?string,
  country?: ?string,
  extra?: ?string,
  active?: ?boolean,
  clientMutationId?: ?string,
|};
export type UpdateAddressMutationVariables = {|
  input: UpdateAddressMutationInput
|};
export type UpdateAddressMutationResponse = {|
  +updateAddressMutation: ?{|
    +ok: ?boolean,
    +error: ?string,
    +user: ?{|
      +addresses: ?{|
        +id: ?string,
        +billingSameAsShipping: ?boolean,
        +shipping: ?{|
          +$fragmentRefs: Address_address$ref
        |},
        +billing: ?{|
          +$fragmentRefs: Address_address$ref
        |},
      |}
    |},
  |}
|};
export type UpdateAddressMutation = {|
  variables: UpdateAddressMutationVariables,
  response: UpdateAddressMutationResponse,
|};
*/


/*
mutation UpdateAddressMutation(
  $input: UpdateAddressMutationInput!
) {
  updateAddressMutation(input: $input) {
    ok
    error
    user {
      addresses {
        id
        billingSameAsShipping
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
      }
    }
  }
}

fragment Address_address on Address {
  firstname
  lastname
  companyName
  phone
  field1
  field2
  field3
  city
  postcode
  country
  extra
  active
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingSameAsShipping",
  "storageKey": null
},
v6 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "Address_address"
  }
],
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstname",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastname",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "companyName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field1",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field2",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field3",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "city",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postcode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "country",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "extra",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "active",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAddressMutationPayload",
        "kind": "LinkedField",
        "name": "updateAddressMutation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Addresses",
                "kind": "LinkedField",
                "name": "addresses",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "shipping",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "billing",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAddressMutationPayload",
        "kind": "LinkedField",
        "name": "updateAddressMutation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Addresses",
                "kind": "LinkedField",
                "name": "addresses",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "shipping",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "billing",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d5578f374b95536eb9795eddda2cad4a",
    "id": null,
    "metadata": {},
    "name": "UpdateAddressMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAddressMutation(\n  $input: UpdateAddressMutationInput!\n) {\n  updateAddressMutation(input: $input) {\n    ok\n    error\n    user {\n      addresses {\n        id\n        billingSameAsShipping\n        shipping {\n          ...Address_address\n        }\n        billing {\n          ...Address_address\n        }\n      }\n    }\n  }\n}\n\nfragment Address_address on Address {\n  firstname\n  lastname\n  companyName\n  phone\n  field1\n  field2\n  field3\n  city\n  postcode\n  country\n  extra\n  active\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1dc7c2551309427986c6521ddc882060';

module.exports = node;
