import _ from 'lodash';
import React, { Component } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import OrderThumb from './OrderThumb';

const S_OrderList = styled.div`
`;

const More = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    background-color: #444;
    color: white;
    font-size: 30px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

class OrderList extends Component {
  constructor () {
    super();
    this.state = {
      loading: false
    };
  }
  

  loadMore () {
    if (!this.props.relay.hasMore()) {
      return;
    } else if (this.props.relay.isLoading()) {
      return;
    }
    this.setState({ loading: true });
    this.props.relay.loadMore(10, () => {
      this.setState({ loading: false });
    });
  }

  renderMore () {
    const { loading } = this.state;
    if (!this.props.relay.hasMore()) return null;
    if (loading) {
      return (
        <More>
          o
        </More>
      );
    }
    return (
      <More>
        <div onClick={() => this.loadMore()}>+</div>
      </More>
    );
  }

  render () {
    const { edges } = this.props.viewer.orders;    
    if (_.isEmpty(edges)) return (
      <div>
        {'Vous n\'avez pas encore réalisé de commande'}
      </div>
    );
    return (
      <div>
        <S_OrderList>
          {
            edges.map(({ node }) => <OrderThumb key={node.__id} order={node}/>)
          }
        </S_OrderList>
        <div>
          {this.renderMore()}
        </div>
      </div>
    );
  }
}

export default createPaginationContainer(
  OrderList, 
  {
    viewer: graphql`
      fragment OrderList_viewer on Viewer {
        orders(
          first: $count
          after: $after
          filter: $filter
        ) @connection(key: "OrderList_orders") {
          edges {
            node {
              ...OrderThumb_order
              orderRef
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  { 
    direction: 'forward',
    query: graphql`
    query OrderListForwardQuery(
      $count: Int!
      $after: String
      $filter: OrderFilter
    ) {
      viewer {
        ...OrderList_viewer
      }
    }
  `,
    getConnectionFromProps (props) {
      return props.viewer && props.viewer.orders;
    },
    getFragmentVariables (previousVariables, totalCount) {
      return {
        ...previousVariables,
        count: totalCount,
      };
    },
    getVariables (props, paginationInfo, fragmentVariables) {
      return {
        count: paginationInfo.count,
        after: paginationInfo.cursor,
        filter: fragmentVariables.filter
      };
    },
  }
);
