/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProductThumb_product$ref: FragmentReference;
declare export opaque type ProductThumb_product$fragmentType: ProductThumb_product$ref;
export type ProductThumb_product = {|
  +id: ?string,
  +slug: ?string,
  +name: ?string,
  +price: ?number,
  +pictures: ?$ReadOnlyArray<?{|
    +filename: ?string
  |}>,
  +variation: ?{|
    +default: ?string,
    +options: ?$ReadOnlyArray<?{|
      +key: ?string,
      +price: ?number,
    |}>,
  |},
  +$refType: ProductThumb_product$ref,
|};
export type ProductThumb_product$data = ProductThumb_product;
export type ProductThumb_product$key = {
  +$data?: ProductThumb_product$data,
  +$fragmentRefs: ProductThumb_product$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductThumb_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductImage",
      "kind": "LinkedField",
      "name": "pictures",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductVariation",
      "kind": "LinkedField",
      "name": "variation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductVariationOption",
          "kind": "LinkedField",
          "name": "options",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '3a7875e413de661821c86ff747143610';

module.exports = node;
