
import _ from 'lodash';
import './OrderPayment.less';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { Redirect, Link } from 'react-router-dom';
import BraintreeMakePaymentMutation from '../../mutations/BraintreeMakePaymentMutation';
import dropin from 'braintree-web-drop-in';
import { Event } from '../../GoogleAnalytics';
import { Button } from '../uikit/UIKit';

class OrderPayment extends Component {

  constructor() {
    super();

    this.state = {
      cgvOk: false
    };
  }

  componentDidMount() {
    dropin.create(
      {
        authorization: this.props.braintreeToken,
        container: this.braintree,
        locale: 'fr_FR',
        threeDSecure: true,
        card: {
          cardholderName: {
            required: true
          }
        }
      },
      (createErr, dropinInstance) => {

        this.order.addEventListener('click', () => {
          dropinInstance.requestPaymentMethod({
            threeDSecure: {
              amount: this.props.amount
            }
          }, (err, payload) => {
            if (_.isNull(err)) {
              BraintreeMakePaymentMutation({ nonce: payload.nonce, message: this.props.message }, ({ status, stockDeltas, order }) => {
                if (status === 'payment_error') {
                  this.setState({ ...this.state, paymentError: true });
                } else if (status === 'out_of_stock') {
                  this.props.onOutOfStock(stockDeltas);
                } else if (status === 'coupon_error') {
                  this.props.onCouponError();
                } else if (status === 'done') {
                  Event.transaction(order);
                  this.props.onOrderDone(order.orderRef);
                }
              });
            } else {
              console.error(err);
            }
          });
        });
      }
    );
  }

  renderCouponError() {
    return (
      <div>
        {'Votre commande n\'a pas pu aboutir : le coupon indiqué n\'est pas valable'}
      </div>
    );
  }

  renderOutOfStock() {
    return (
      <div>
        {'Votre commande n\'a pas pu aboutir : nos stocks ont évolués et nous ne pouvons satisfaire les quantités demandées.'}
        <br />
        {'Vérifiez les articles surligné en rouge dans la section \'Détails de votre commande\'.'}
        <br />
        {'Pour finaliser votre commande veuillez ajuster vos quantités.'}
      </div>
    );
  }

  renderCGV() {
    const { cgvOk } = this.state;
    return (
      <div className="payment-accept-cgv">
        <a
          href="javascript:void(0)"
          style={{ marginRight: '8px' }}
          onClick={e => {
            e.preventDefault();
            this.setState({ ...this.state, cgvOk: !cgvOk });
          }}>
          <FontAwesomeIcon style={{ color: '#999' }} size="2x" icon={cgvOk ? faCheckCircle : faCircle} />
        </a>
        <div>
          Merci de nous confirmer que vous acceptez nos&nbsp;
          <span><Link to="/order?modal=cgv">conditions générales de ventes</Link>&nbsp;?</span>
        </div>
      </div>
    );
  }

  renderPaymentButton() {
    return (
      <button
        className={classNames('uikit-button uikit-button-big payment-button', { 'uikit-button-disabled': !this.state.cgvOk })}
        ref={r => this.order = r}
      >
        Payer
      </button>
    );
  }
  render() {
    const { outOfStock, couponError } = this.props;
    const { cgvOk, paymentError } = this.state;

    return (
      <div className="order-payment block">
        <div className="block-top">
          <h2>Paiement</h2>
        </div>
        <div className={classNames('block-body', { 'block-body-error': paymentError })}>
          {
            paymentError && <Redirect to="/order/paymenterror" />
          }
          {
            !paymentError && outOfStock && this.renderOutOfStock()
          }
          {
            !paymentError && couponError && this.renderCouponError()
          }
          {
            !paymentError && !outOfStock && !couponError &&
            <div className="payment-form">
              <div className="payment-form-text">
                <div className="payment-form-text-title">{'Satisfait ou remboursé !'}</div>
                <div>{'Conformément à la loi vous avez 14 jours après le réglement de votre commande pour nous notifier que vous souhaitez son remboursement total ou partiel (hors frais de port).'}</div>
                <div>{'Suite à cette notification vous aurez 7 jours pour nous retourner les articles (à vos frais).'}</div>
                <div>{'Nous vous remboursons dans un délai de 14 jours après réception de votre retour.'}</div>
                <div className="payment-form-text-title" style={{ marginTop: '32px' }}>{'Partenaire pour le paiement'}</div>
                <div>
                  {'Pour le paiement nous faisons confiance à'}
                  <img className="ml-2 t-payment-braintree-img" src="/images/braintree-logo.png" />
                </div>
              </div>
              <div className="braintree-area" ref={r => this.braintree = r} />
              {
                this.renderCGV()
              }
              {
                this.renderPaymentButton()
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

OrderPayment.propTypes = {
  amout: PropTypes.number,
  onOutOfStock: PropTypes.func,
  outOfStock: PropTypes.bool,
  onOrderDone: PropTypes.func,
  onPaymentError: PropTypes.func,
  onCouponError: PropTypes.func
};

export default OrderPayment;