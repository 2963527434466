import './Button.less';
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
  }

  render() {
    const elementProps = { ... this.props };
    delete elementProps.fetching;
    delete elementProps.small;
    delete elementProps.disabled;

    return React.createElement(
      _.isEmpty(elementProps.to) ? 'button' : Link,
      {
        ...elementProps,
        className: classNames(
          'uikit-button', {
          'uikit-button-small': this.props.small,
          'uikit-button-big': this.props.size === 'big',
          'uikit-button-disabled': this.props.disabled
        })
      },
      <div>
        {this.props.children}
        {
          this.props.fetching &&
          <FontAwesomeIcon className="ml-4" icon={faCircleNotch} spin />
        }
      </div>
    );
  }
}

Button.defaultProps = {
  disabled: false,
  href: '',
  to: '',
  type: '',
  className: 'uikit-button',
  size: 'normal',
  small: false,
  fetching: false,
  onClick: () => { }
};

Button.propTypes = {
  disabled: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.oneOf(['big', 'normal']),
  small: PropTypes.bool,
  fetching: PropTypes.bool,
  onClick: PropTypes.func
};

export default Button;