import './TopMobile.less';
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons'; import { withRouter } from 'react-router-dom';

const TopMobile = ({
  location,
  isMobile,
  toggleShowMenu,
  menuIsOpened
}) => {

  const locationRef = useRef(location);

  useEffect(() => {
    if (location.pathname !== locationRef.current.pathname && menuIsOpened) {
      locationRef.current = location;
      toggleShowMenu();
    }
  });

  return (
    <div className="top-mobile">
      <div className="top-mobile-main">
        <div className="top-mobile-bars">
          <a href="javascript:void(0);" onClick={toggleShowMenu}>
            <FontAwesomeIcon icon={faBars} size="2x" />
          </a>
        </div>
        <div className="top-mobile-title">
          {'les petites emplettes'}
        </div>
      </div>
    </div>
  );
};

TopMobile.defaultProps = {
  isMobile: false
};

TopMobile.PropTypes = {
  isMobile: PropTypes.boolean
};
export default withRouter(TopMobile);