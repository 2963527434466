import './Menu.less';
import React, { Component } from 'react';
import MenuMobile from './MenuMobile';
import PropTypes from 'prop-types';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Switch, Route } from 'react-router-dom'; 
import L1 from './L1';
import MenuProducts from './MenuProducts';

const MenuQuery = graphql`
  query MenuQuery {
    viewer {
      categories {
        name
        description
        url 
        redirect
        children {
          name
          description
          url
          redirect
          children {
            name
            description
            url
            redirect
          }
        }
      }
    }
  } 
`;

const Menu = ({
  isMobile,
  show
}) => {
  return (
    <Switch>
      {
        !isMobile &&
          <Route path="/order" component="null"/>
      }
      {
        !isMobile &&
          <Route path="/myaccount" component="null"/>
      }
      <QueryRenderer
        environment={environment}
        query={MenuQuery}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            if (!isMobile) {
              const l1s = [...props.viewer.categories];
              return (
                <div className="menu">
                  {l1s.map((c, index) => (
                    <L1 
                      key="index" 
                      pos={index} 
                      title={c.name} 
                      description={c.description} 
                    >
                      <MenuProducts categories={c.children}/>
                    </L1>
                  ))}
                </div>
              );
            } else {
              return (
                <MenuMobile show={show} categories={props.viewer.categories}/>
              );
            }
          }
          return <div>Loading</div>;
        }}
      />
    </Switch>
  );
};

Menu.propTypes = {
  fetching: PropTypes.bool.isRequired,
  categoriesFetch: PropTypes.func.isRequired
};

export default Menu;