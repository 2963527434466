import _ from 'lodash';
import React, { Component } from 'react';
import update from 'react-addons-update';
import styled from 'styled-components';
import { Button } from '../../uikit/UIKit';

import Address from './Address';

import UpdateBillingSameAsShippingMutation from '../../../mutations/UpdateBillingSameAsShippingMutation';
const S_BillingAddress = styled.div`
  a.billing-check-box {
    display: flex;
    align-items: center;
    margin-top: 16px;
    text-decoration: none;
    color: #444;
  }
`;

class BillingAddress extends Component {
  setSameAsShipping (value) {
    UpdateBillingSameAsShippingMutation(value, () => {
    });
  }

  render () {
    const { address, billingSameAsShipping } = this.props;
    return (
      <S_BillingAddress>
        {
          !billingSameAsShipping &&
          <a className="billing-check-box" href="javascript:void(0)" onClick={() => this.setSameAsShipping(true)}>
            <i style={{ marginRight: '8px' }} className="fa fa-square-o"/>{'L\'adresse de facturation est identique à l\'adresse de livraison'}
          </a>
        }
        {
          billingSameAsShipping && 
          <a className="billing-check-box" href="javascript:void(0)" onClick={() => this.setSameAsShipping(false)}>
            <i style={{ marginRight: '8px' }} className="fa fa-check-square-o"/>{'L\'adresse de facturation est identique à l\'adresse de livraison'}
          </a>
        }
        {
          !billingSameAsShipping && 
          <div>
            <h3>Votre adresse de facturation</h3>
            <Address {...{ address, type: 'billing' }}/>
          </div>
        }
      </S_BillingAddress>
    );
  }
}

export default BillingAddress;