import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation UpdateCartCountryMutation($input: UpdateCartCountryMutationInput!) {
  updateCartCountryMutation(input: $input) { 
    ok
    error
    cart {
      total {
        ...CartTotalFragment_total
      }
    }
  }
}
`;

export default (country, done) => {
  const variables = {
    input: {
      country
    }
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};