import _ from 'lodash';
import qs from 'query-string';
import React, { Component } from 'react';
import Top from './Top';
import TopMobile from './TopMobile';
import AcceptCookies from '../misc/AcceptCookies';
import Footer from './footer/Footer';
import Menu from '../menu/Menu';
import { withRouter } from 'react-router-dom';
import { withIsMobile } from './IsMobile';
class Template extends Component {

  constructor (props) {
    super(props);
    this.scrollTopVal = 0;
    this._div = null;

    this.state = {
      showMenu: false
    };
  }

  checkIfModalIsOn (location) {
    const query = qs.parse(location.search);
    return _.has(query, 'modal');    
  }

  handleScroll (event) {
    if (!this.lockVerticalScrolling) {
      this.scrollTopVal = event.srcElement.body.scrollTop;
    }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll.bind(this));
    if (this.checkIfModalIsOn(this.props.location)) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${this.scrollTopVal}px`;
    } else {
      document.body.style.position = 'relative';
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.checkIfModalIsOn(nextProps.location)) {
      this.lockVerticalScrolling = true;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${this.scrollTopVal}px`;
      
    } else {
      this.lockVerticalScrolling = false;
      document.body.style.position = '';
      window.scrollTo(0, this.scrollTopVal);
    }
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  toggleShowMenu () {
    this.setState({ showMenu: !this.state.showMenu });
  }

  render () {
    const { children } = this.props;
    if (!this.props.isMobile) {
      return (
        <div className="template">
          <Top/>
          <div className="template-body">
            <div className="template-main">
              {children}
            </div>
          </div>
          <Footer className="footer"/>
          <div className="footer-image">
            <img src="/images/craspedia.png" width="200px"/>
          </div>
          <AcceptCookies/>
        </div>
      );
    } else {
      return (
        <div className="template-mobile">
          <TopMobile 
            toggleShowMenu={() => this.toggleShowMenu()}
            menuIsOpened={this.state.showMenu}
          />
          <Menu isMobile={true} show={this.state.showMenu}/>
          <div className="template-mobile-body">
            <div className="template-mobile-main">
              {children}
            </div>
          </div>
          <AcceptCookies/>
        </div>
      );
    }
    
  }
}

export default withRouter(withIsMobile(Template));