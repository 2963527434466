import _ from 'lodash';
import React, { Component } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Loading } from '../uikit/UIKit';
import CategoryNotFound from './CategoryNotFound';
import Dimensions from 'react-dimensions';

import ProductList from './ProductList';

import { isAdmin } from '../../libs';

import {  
  withRouter
} from 'react-router-dom';

import { 
  branch,
  compose,
  lifecycle,
  renderNothing,
  withHandlers,
  withProps,
  withState
} from 'recompose';

const PRODUCT_WIDTH = 230;

const ProductListPageQuery = graphql`
  query ProductListPageQuery(
    $count: Int!
    $after: String
    $filter: ProductFilterInput
    $path: String
  ) {
    viewer {
      user {
        id
        email
        firstname
        lastname
      }
      ...ProductList_viewer
      category(path: $path) {
        id
        name
        description        
      }
    }
  } 
`;

const enhance = compose(
  withRouter,
  Dimensions(),
  withState(
    'productPerRow',
    'setProductPerRow',
    -1
  ),
  withHandlers({
    computeProductPerRow: ({ setProductPerRow, containerWidth }) => () => {
      let productPerRow;
      const width = containerWidth;
      productPerRow = Math.floor(width / PRODUCT_WIDTH);
      productPerRow = (width - (productPerRow * PRODUCT_WIDTH)) / ((productPerRow - 1) * 2) > 30 ? productPerRow : productPerRow - 1; 
      setProductPerRow(productPerRow);
    }
  }),
  lifecycle({
    componentDidMount () {
      this.props.computeProductPerRow();
      window.onresize = () => {
        this.props.computeProductPerRow();
      };
    }
  }),
  withProps(
    ({ match }) => ({
      path: match.params[0]
    })
  )
);

const ProductListPage = enhance(
  branch(
    ({ productPerRow }) => productPerRow === -1,
    renderNothing
  )(
    ({
      path,
      productPerRow
    }) => (
      <div>
        <QueryRenderer
          environment={environment}
          query={ProductListPageQuery}
          variables={{
            count: productPerRow * 4,
            filter: { path: path },
            path
          }}
          render={({ error, props }) => {
            if (error) {
              return (
                <div className="block">
                  <div className="block-body">
                    {error.message}
                  </div>
                </div>
              );
            } else if (props) {
              if (_.isNull(props.viewer.category)) return <CategoryNotFound/>;
              return (
                <ProductList
                  isAdmin = {isAdmin(props.viewer.user.email)}
                  category={props.viewer.category}
                  productWidth={PRODUCT_WIDTH}
                  productPerRow={productPerRow} 
                  viewer={props.viewer} />
              );
            }
            return <Loading/>;
          }}
        />
      </div>
    )
  )
);

const ProductListPageWrapper = () => (
  <div className="block block-large">
    <ProductListPage/>
  </div>
);

export default ProductListPageWrapper;