import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation ApplyCouponMutation($input: ApplyCouponMutationInput!) {
    applyCouponMutation(input: $input) { 
      ok,
      error,
      cart {
        id
        coupon {
          code
          amount
        }
        total {
          totalWithTaxWithoutCoupon
          totalWithTax
        }
      }
    }
  }
`;

export default ({ code }, onDone) => {
  const variables = {
    input: {
      code
    },
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (store) => {
        /*
        const root = store.getRootField('addToCart');
        const nbOfItems = root.getValue('nbOfItems');
        const cartTop = store.get('cartTop');
        cartTop.setValue(nbOfItems, 'nbOfItems');
        */
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: (response) => {
        const { cart } = response.applyCouponMutation;
        onDone(cart);
      },
      onError: err => console.error(err),
    }
  );
};