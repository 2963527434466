import accounting from 'accounting';
import Moment from 'moment';

class Format {

  static PriceToString(value, currency = '€') {
    return accounting.formatMoney(value, '€', 2, ' ', ',', '%v %s');
  }

  static DateToString(value) {
    console.log('=====>', value);
    return Moment(value).format('DD/MM/YY HH:mm');
  }
}

export default Format;