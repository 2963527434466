/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HousingPageQueryVariables = {|
  slug?: ?string
|};
export type HousingPageQueryResponse = {|
  +viewer: ?{|
    +cmsHousing: ?{|
      +id: ?string,
      +title: ?string,
      +description: ?string,
      +meta: ?{|
        +description: ?string
      |},
      +picture1: ?{|
        +title: ?string,
        +url: ?string,
      |},
      +picture2: ?{|
        +title: ?string,
        +url: ?string,
      |},
      +rooms: ?$ReadOnlyArray<?{|
        +title: ?string,
        +description: ?string,
        +gallery: ?$ReadOnlyArray<?{|
          +title: ?string,
          +url: ?string,
        |}>,
      |}>,
      +bookingDescription: ?string,
    |}
  |}
|};
export type HousingPageQuery = {|
  variables: HousingPageQueryVariables,
  response: HousingPageQueryResponse,
|};
*/


/*
query HousingPageQuery(
  $slug: String
) {
  viewer {
    cmsHousing(slug: $slug) {
      id
      title
      description
      meta {
        description
      }
      picture1 {
        title
        url
      }
      picture2 {
        title
        url
      }
      rooms {
        title
        description
        gallery {
          title
          url
        }
      }
      bookingDescription
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "slug"
    }
  ],
  "concreteType": "Housing",
  "kind": "LinkedField",
  "name": "cmsHousing",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Meta",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CmsImage",
      "kind": "LinkedField",
      "name": "picture1",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CmsImage",
      "kind": "LinkedField",
      "name": "picture2",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HousingRoom",
      "kind": "LinkedField",
      "name": "rooms",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CmsImage",
          "kind": "LinkedField",
          "name": "gallery",
          "plural": true,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookingDescription",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HousingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HousingPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac52a2bae9ff75e4129793f92f8bb1bc",
    "id": null,
    "metadata": {},
    "name": "HousingPageQuery",
    "operationKind": "query",
    "text": "query HousingPageQuery(\n  $slug: String\n) {\n  viewer {\n    cmsHousing(slug: $slug) {\n      id\n      title\n      description\n      meta {\n        description\n      }\n      picture1 {\n        title\n        url\n      }\n      picture2 {\n        title\n        url\n      }\n      rooms {\n        title\n        description\n        gallery {\n          title\n          url\n        }\n      }\n      bookingDescription\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '06213e5278bce9be9563dd98bca048ad';

module.exports = node;
