import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation SignOutMutation($input: SignOutMutationInput!){
  signOutMutation(input:$input) {
    ok
    error
    user {
      id
      firstname
      lastname
      email
      addresses {
        id
        billingSameAsShipping
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
      }
    }
  }
}
`;

export default (done) => {
  const variables = { input: {} };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};