import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import BlogArticleThumb from './BlogArticleThumb';
import { Loading } from '../uikit/UIKit';

const ArticlesQuery = graphql`
  query BlogListPageQuery {
    viewer {
      cmsArticles {
        id
        title
        thumbnailUrl
      }
    }
  }
`;

const BlogListPage = () => {
  return (
    <div className="blog-list">
      <QueryRenderer
        environment={environment}
        query={ ArticlesQuery }
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return (
              <ul className="blog-article-list">
                {
                  props.viewer.cmsArticles.map(({ title, id, thumbnailUrl }, index) => (
                    <li key={index}>
                      <BlogArticleThumb
                        title={title}
                        id={id}
                        thumbnailUrl={thumbnailUrl}
                        delay={index * 100}
                      />
                    </li>
                  ))
                }
              </ul>
            );
          }
          return <Loading/>;
        }}
      />
    </div>
  );
};

export default BlogListPage;