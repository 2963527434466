/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type OrderState = "cancelled" | "closed" | "new" | "on_its_way" | "packing" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderStatus_status$ref: FragmentReference;
declare export opaque type OrderStatus_status$fragmentType: OrderStatus_status$ref;
export type OrderStatus_status = {|
  +state: ?OrderState,
  +history: ?$ReadOnlyArray<?{|
    +state: ?OrderState,
    +date: ?string,
  |}>,
  +$refType: OrderStatus_status$ref,
|};
export type OrderStatus_status$data = OrderStatus_status;
export type OrderStatus_status$key = {
  +$data?: OrderStatus_status$data,
  +$fragmentRefs: OrderStatus_status$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderStatus_status",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderHistory",
      "kind": "LinkedField",
      "name": "history",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderStatus",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a324ef97a7f1dbba462686b689e8e476';

module.exports = node;
