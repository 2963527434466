import './less/store.less';
import 'react-select/dist/react-select.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './css/tailwind.css';

import React from 'react';
import ReactDOM from 'react-dom';
import Tokens from './lib/Tokens';
import Routes from './Routes';

Tokens.setKey('lpe', 'IWX9BL9UMHSM7J1VV6FXSYIMCAHSN0KZ367R6UHL1DIPPXUR');

const getDocumentRoot = () => {
  var element = document.createElement('div');
  document.body.appendChild(element);
  return element;
};

ReactDOM.render(
  <Routes />,
  getDocumentRoot()
);
