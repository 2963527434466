import { assoc, isEmpty, pathOr, propOr, toUpper } from 'ramda';
import React from 'react';
import ApplyCouponMutation from '../../mutations/ApplyCouponMutation';

const CartCoupon = ({
  value
}) => {
  const [coupon, setCoupon] = React.useState({ code: '', editing: true });

  const $onCouponChange = (e) => {
    setCoupon({
      code: e.target.value,
      editing: true
    });
  };

  const $onCouponRequest = (e) => {
    e.preventDefault();
    setCoupon(assoc('editing', false));
  };

  const $onCouponCancel = (e) => {
    e.preventDefault();
    ApplyCouponMutation({ code: '' }, (cart) => {
      setCoupon({ code: '', editing: true });
    });
  };

  const applyCoupon = (code) => {
    ApplyCouponMutation({ code: toUpper(code) }, (cart) => {
      if (pathOr('', ['coupon', 'code'], cart) !== toUpper(coupon.code)) (
        setCoupon({
          code: '',
          editing: true
        })
      );
    });
  };

  React.useEffect(() => {
    if (propOr('', 'code', value) !== '') applyCoupon(value.code);
  }, []);

  const editingRef = React.useRef(true);

  React.useEffect(() => {
    if (coupon.editing !== editingRef.current) {
      editingRef.current = coupon.editing;
      if (!coupon.editing) applyCoupon(coupon.code);
    }
  }, [coupon.editing]);

  const cartCouponCode = propOr('', 'code', value);
  // le caddy ne contient pas de coupon
  if (isEmpty(cartCouponCode)) return (
    <div className="t-coupon-wrapper">
      <input
        className="t-coupon-input"
        placeholder="code"
        onChange={$onCouponChange}
        value={coupon.code}
      />
      <button className="mt-4 t-btn t-btn-green" onClick={$onCouponRequest}>Valider</button>
    </div >
  );
  return (
    <div className="t-coupon-wrapper">
      <div>Vous avez activé le coupon : <b>{value.code}</b></div>
      <button className="mt-4 t-btn t-btn-green" onClick={$onCouponCancel}>Annuler</button>
    </div>
  );
};

export default CartCoupon;