import './ProductThumb.less';

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { Motion, spring } from 'react-motion';
import accounting from 'accounting';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

const S_ProductThumb = styled(Link)`
  //background-color: white;
  overflow: hidden;
  width: 230px;
  .description {
    background-color: #fbfbfb;
    width: 100%;
  }
  .product-thumb-title {
    margin-top: 12px;
    font-size: 12px;
    letter-spacing: 1.1;
  }
  .product-thumb-price {
    font-size: 14px;
    margin: 8 0;
  }
`;

class ProductThumb extends Component {

  getPrice() {
    const { variation, price } = this.props.product;
    if (!_.isEmpty(variation.default)) {
      const options = _.keyBy(variation.options, 'key');
      if (!_.isUndefined(options[variation.default])) {
        return options[variation.default].price;
      }
    }
    return price;
  }

  render() {
    const { id, slug, name, pictures, price } = this.props.product;

    const img = !_.isUndefined(pictures[0]) ? pictures[0].filename : 'fake';
    return (
      <Motion defaultStyle={{ opacity: 0 }} style={{ opacity: spring(1) }}>
        {({ opacity }) => (
          <S_ProductThumb style={{ opacity }} to={`/product/${slug}`}>
            {
              img === 'fake' &&
              <div className="lpe-image" style={{ width: '230px', height: '345px', backgroundColor: '#e7e7e7' }} />
            }
            {
              img !== 'fake' &&
              <img className="lpe-image" src={`/image/${img}/400-3-2`} width={230} height={345} />
            }
            <div className="description">
              <div className="product-thumb-title">
                {name}
              </div>
              <div className="product-thumb-price">
                {accounting.formatMoney(this.getPrice(), '€', 2, ' ', ',', '%v %s')}
              </div>
            </div>
          </S_ProductThumb>
        )}
      </Motion>
    );
  }
}

ProductThumb.propTypes = {
  name: PropTypes.string,
  pictures: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string
    })
  )
};

export default createFragmentContainer(
  ProductThumb, {
  product:
    graphql`
    fragment ProductThumb_product on Product {
      id,
      slug,
      name,
      price,
      pictures {
        filename
      }
      variation {
        default
        options {
          key
          price
        }
      }
    }
  `
});