import _ from 'lodash';
import React, { Component } from 'react';
import update from 'react-addons-update';
import PropTypes from 'prop-types';
import { Input, Button } from '../../uikit/UIKit';
import CountryList from './CountryList';
import Select from 'react-select';
import CreateAddressMutation from '../../../mutations/CreateAddressMutation';
import UpdateAddressMutation from '../../../mutations/UpdateAddressMutation';

class EditAddress extends Component {
  constructor (props) {
    super(props);
    this.state = {
      address: props.address,
      fetching: false
    };
  }

  onSubmit (e) {
    const { type, create } = this.props;

    e.preventDefault();
    this.setState(update(this.state, { $set: { fetching: true } }));
    if (create) {
      return CreateAddressMutation({ ...this.state.address, type }, () => {
        setTimeout(() => {
          this.setState(update(this.state, { $set: { fetching: false } }));  
        }, 200); 
      });
    } else {
      return UpdateAddressMutation({ ...this.state.address, type }, () => {
        setTimeout(() => {
          this.setState(update(this.state, { $set: { fetching: false } }));  
        }, 200);       
      });
    }
  }

  updateField (fieldname, value) {
    this.setState(update(this.state, { address: { [fieldname]: { $set: value } } }));
  }

  render () {
    const { firstname, lastname, companyName, phone, field1, field2, field3, city, postcode, country, extra, active } = this.state.address;
    return (
      <form onSubmit={e => this.onSubmit(e)}>
        <Input placeholder="Prénom" value={firstname} onChange={v => this.updateField('firstname', v)}/>
        <Input placeholder="Nom" value={lastname} onChange={v => this.updateField('lastname', v)}/>
        <Input placeholder="Société" value={companyName} onChange={v => this.updateField('companyName', v)}/>
        <Input placeholder="Téléphone" value={phone} onChange={v => this.updateField('phone', v)}/>
        <Input placeholder="Adresse 1" value={field1} onChange={v => this.updateField('field1', v)}/>
        <Input placeholder="Adresse 2" value={field2} onChange={v => this.updateField('field2', v)}/>
        <Input placeholder="Adresse 3" value={field3} onChange={v => this.updateField('field3', v)}/>
        <Input placeholder="Ville" value={city} onChange={v => this.updateField('city', v)}/>
        <Input placeholder="Code postal" value={postcode} onChange={v => this.updateField('postcode', v)}/>
        <Select placeholder="Pays" value={country} options={CountryList.get('fr')} simpleValue={true} onChange={v => this.updateField('country', v)}/>
        <div>
          <Button 
            fetching={this.state.fetching}
            style={{ marginTop: '16px' }} 
            type="submit"
          >
            {'Enregistrer'}
          </Button>
          {
            !_.isEmpty(this.props.address) && <Button onClick={() => this.props.onClose()}>Fermer</Button>
          }
        </div>

      </form>
    );
  }
}

EditAddress.EmptyAdress = {
  firstname: '',
  lastname: '',
  companyName: '',
  phone: '',
  field1: '',
  field2: '',
  field3: '',
  city: '',
  postcode: '',
  country: '',
  extra: '',
  active: true
};

EditAddress.propTypes = {
  onClose: PropTypes.func
};

export default EditAddress;