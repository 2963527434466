/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CartTopQueryVariables = {||};
export type CartTopQueryResponse = {|
  +viewer: ?{|
    +cart: ?{|
      +id: ?string,
      +nbOfItems: ?number,
      +total: ?{|
        +id: ?string,
        +productsWithTax: ?number,
      |},
    |}
  |}
|};
export type CartTopQuery = {|
  variables: CartTopQueryVariables,
  response: CartTopQueryResponse,
|};
*/


/*
query CartTopQuery {
  viewer {
    cart {
      id
      nbOfItems
      total {
        id
        productsWithTax
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Cart",
  "kind": "LinkedField",
  "name": "cart",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nbOfItems",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CartTotal",
      "kind": "LinkedField",
      "name": "total",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productsWithTax",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CartTopQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CartTopQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d92464ee283a82f5f53816880d36bb7",
    "id": null,
    "metadata": {},
    "name": "CartTopQuery",
    "operationKind": "query",
    "text": "query CartTopQuery {\n  viewer {\n    cart {\n      id\n      nbOfItems\n      total {\n        id\n        productsWithTax\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2bafc3a917d59fc830fc0cbd317e21a5';

module.exports = node;
