/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductPageQueryVariables = {|
  slug?: ?string
|};
export type ProductPageQueryResponse = {|
  +viewer: ?{|
    +product: ?{|
      +id: ?string,
      +name: ?string,
      +slug: ?string,
      +redirect: ?string,
      +description: ?string,
      +price: ?number,
      +stock: ?number,
      +shippingCost: ?number,
      +url: ?string,
      +pictures: ?$ReadOnlyArray<?{|
        +filename: ?string
      |}>,
      +variation: ?{|
        +name: ?string,
        +default: ?string,
        +options: ?$ReadOnlyArray<?{|
          +key: ?string,
          +name: ?string,
          +price: ?number,
          +shippingCost: ?number,
          +stock: ?number,
        |}>,
      |},
    |}
  |}
|};
export type ProductPageQuery = {|
  variables: ProductPageQueryVariables,
  response: ProductPageQueryResponse,
|};
*/


/*
query ProductPageQuery(
  $slug: String
) {
  viewer {
    product(slug: $slug) {
      id
      name
      slug
      redirect
      description
      price
      stock
      shippingCost
      url
      pictures {
        filename
      }
      variation {
        name
        default
        options {
          key
          name
          price
          shippingCost
          stock
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stock",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingCost",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "slug"
    }
  ],
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "redirect",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductImage",
      "kind": "LinkedField",
      "name": "pictures",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductVariation",
      "kind": "LinkedField",
      "name": "variation",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductVariationOption",
          "kind": "LinkedField",
          "name": "options",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            (v2/*: any*/),
            (v3/*: any*/),
            (v5/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca18cd7fb730778b60149891f0102624",
    "id": null,
    "metadata": {},
    "name": "ProductPageQuery",
    "operationKind": "query",
    "text": "query ProductPageQuery(\n  $slug: String\n) {\n  viewer {\n    product(slug: $slug) {\n      id\n      name\n      slug\n      redirect\n      description\n      price\n      stock\n      shippingCost\n      url\n      pictures {\n        filename\n      }\n      variation {\n        name\n        default\n        options {\n          key\n          name\n          price\n          shippingCost\n          stock\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e712f3b629a0541008f31cf294a2209d';

module.exports = node;
