import './Order.less';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Format from '../../helpers/Format';
import OrderTotal from './OrderTotal';
import OrderItemFragment from './OrderItemFragment';
import OrderStatus from './OrderStatus';

const Order = ({ orderRef, date, items, total, status, message }) => (
  <React.Fragment>
    <div className="block">
      <div className="block-top">
        <h2>Commande</h2>
      </div>
      <div className="block-body">
        <div className="cart-row">
      N° : {orderRef}
        </div>
        <div className="cart-row">
        Date : le {Format.DateToString(date)}
        </div>
      </div>
    </div>
    <div className="block">
      <div className="block-top">
        <h2>Liste des produits</h2>
      </div>
      <div className="block-body">
        {
          items.map((item, index) => <OrderItemFragment key={index} {...{ item }}/>)
        }
      </div>
    </div>
    <div className="block">
      <div className="block-top">
        <h2>Total</h2>
      </div>
      <div className="block-body">
        <OrderTotal total={total}/>
      </div>
    </div>
    <div className="block">
      <div className="block-top">
        <h2>Votre message</h2>
      </div>
      <div className="block-body">
        {
          !_.isEmpty(message) ? message : 'vous n\'avez pas laissé de message'
        }
      </div>
    </div>
    <div className="block">
      <div className="block-top">
        <h2>Statut de la commande</h2>
      </div>
      <div className="block-body">
        <OrderStatus {...{ status }}/>
      </div>
    </div>
  </React.Fragment>
);

Order.propTypes = {
  orderRef: PropTypes.string,
  message: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.object,
  total: PropTypes.object
};

export default Order;