import React, { Component } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';

import Order from './Order';

const OrderPageQuery = graphql`
  query OrderPageQuery(
    $id: ID
  ) {
    viewer {
      order(id: $id) {
        id
        date,
        orderRef
        message
        coupon {
          id
          code
          amount
        }
        status {
          ...OrderStatus_status
        }
        total {
          ...OrderTotal_total
        }
        items {
          ...OrderItemFragment_item
        }
      }
    }
  } 
`;

class OrderPage extends Component {

  render() {
    const { orderRef } = this.props;
    return (
      <QueryRenderer
        environment={environment}
        query={OrderPageQuery}
        variables={{
          id: orderRef
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return (
              <Order {...props.viewer.order} />
            );
          }
          return <div>Loading</div>;
        }}
      />
    );
  }
}

export default OrderPage;