import './ProductList.less';

const R = require('ramda');

import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import ProductThumb from './ProductThumb';
import classNames from 'classnames';
import { Back } from '../uikit/UIKit';

import More from './More';
import NoProducts from './NoProducts';
import ProductListDescription from './ProductListDescription';

import {
  branch,
  compose,
  mapProps,
  renderComponent,
  withHandlers,
  withState,
} from 'recompose';

const enhance = compose(
  withState(
    'loading',
    'setLoading',
    false
  ),
  withHandlers({
    loadMore: ({ productPerRow, relay, setLoading }) => () => {
      if (!relay.hasMore() || relay.isLoading()) return;
      setLoading(true);
      relay.loadMore(productPerRow * 2, () => {
        setLoading(false);
      });
    },
    prepareProducts: ({ viewer, productPerRow }) => () => {
      const rows = [];
      let currentRow = -1;
      viewer.products.edges.map(({ node }, index) => {
        if (index % productPerRow === 0) {
          currentRow++;
          rows[currentRow] = [];
        }
        rows[currentRow].push(node);
      });
  
      let i = rows[currentRow].length;
  
      while (i < productPerRow) {
        rows[currentRow].push({ __fake: true });
        i++;
      }
      return rows;
    }
  }),
  mapProps(
    ({
      category, 
      isAdmin,
      loading, 
      loadMore,
      relay, 
      viewer, 
      prepareProducts,
      productPerRow,
      productWidth
    }) => ({
      category,
      isAdmin,
      loading,
      loadMore, 
      hasMore: relay.hasMore(),
      products: R.isEmpty(viewer.products.edges) ? [] : prepareProducts(),
      productPerRow,
      productWidth
    })
  )
);

const ProductList = enhance(
  branch(
    ({ products }) => R.isEmpty(products),
    renderComponent(NoProducts)
  )(
    ({
      category,
      isAdmin,
      loading,
      loadMore,
      hasMore,
      products,
      productPerRow,
      productWidth
    }) => <React.Fragment>
      <div className="block block-large">
        <ProductListDescription isAdmin={isAdmin} category={category}/>
        <div className="block-title">
          <h2>{category.name}</h2>
        </div>
        <div className="block-body">
          {products.map((row, index) => (
            <div className={classNames('product-list-row', { 'product-list-row-one-item': productPerRow === 1 })} key={index}>
              {
                row.map((node, index) => {
                  if (R.isNil(node.__fake)) {
                    return (
                      <ProductThumb key={node.__id} product={node}/>
                    );
                  }
                  //return <a key={index} style={{ height: 0, width: `${productWidth}px` }}/>;
                })
              }
            </div>
          ))}
        </div>
      </div>
      <div className="block-bottom">
        <More 
          hasMore={hasMore}
          loading={loading}
          onLoadMore={loadMore}
        />
      </div>
      <Back/>
    </React.Fragment>
  )
);

export default createPaginationContainer(
  ProductList, 
  {
    viewer: graphql`
      fragment ProductList_viewer on Viewer {
        products(
          first: $count
          after: $after
          filter: $filter
        ) @connection(key: "ProductList_products") {
          edges {
            node {
              ...ProductThumb_product
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  { 
    direction: 'forward',
    query: graphql`
    query ProductListForwardQuery(
      $count: Int!
      $after: String
      $filter: ProductFilterInput
    ) {
      viewer {
        ...ProductList_viewer
      }
    }
  `,
    getConnectionFromProps (props) {
      return props.viewer && props.viewer.products;
    },
    getFragmentVariables (previousVariables, totalCount) {
      return {
        ...previousVariables,
        count: totalCount,
      };
    },
    getVariables (props, paginationInfo, fragmentVariables) {
      return {
        count: paginationInfo.count,
        after: paginationInfo.cursor,
        filter: fragmentVariables.filter
      };
    },
  }
);
