/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CartItemFragment_item$ref = any;
type CartTotalFragment_total$ref = any;
export type BraintreeMakePaymentMutationInput = {|
  nonce?: ?string,
  amount?: ?number,
  message?: ?string,
  clientMutationId?: ?string,
|};
export type BraintreeMakePaymentMutationVariables = {|
  input: BraintreeMakePaymentMutationInput
|};
export type BraintreeMakePaymentMutationResponse = {|
  +braintreeMakePaymentMutation: ?{|
    +status: ?string,
    +cart: ?{|
      +id: ?string,
      +total: ?{|
        +id: ?string,
        +$fragmentRefs: CartTotalFragment_total$ref,
      |},
      +items: ?$ReadOnlyArray<?{|
        +$fragmentRefs: CartItemFragment_item$ref
      |}>,
    |},
    +order: ?{|
      +orderRef: ?string,
      +total: ?{|
        +id: ?string,
        +productsWithTax: ?number,
        +shippingWithTax: ?number,
        +totalWithTax: ?number,
        +tax: ?number,
      |},
    |},
    +stockDeltas: ?$ReadOnlyArray<?{|
      +id: ?string,
      +value: ?number,
    |}>,
  |}
|};
export type BraintreeMakePaymentMutation = {|
  variables: BraintreeMakePaymentMutationVariables,
  response: BraintreeMakePaymentMutationResponse,
|};
*/


/*
mutation BraintreeMakePaymentMutation(
  $input: BraintreeMakePaymentMutationInput!
) {
  braintreeMakePaymentMutation(input: $input) {
    status
    cart {
      id
      total {
        id
        ...CartTotalFragment_total
      }
      items {
        ...CartItemFragment_item
      }
    }
    order {
      orderRef
      total {
        id
        productsWithTax
        shippingWithTax
        totalWithTax
        tax
      }
      id
    }
    stockDeltas {
      id
      value
    }
  }
}

fragment CartItemFragment_item on CartItem {
  id
  name
  price
  qty
  option {
    title
    value
  }
}

fragment CartTotalFragment_total on CartTotal {
  id
  country
  productsWithTax
  productsNoTax
  shippingNoTax
  shippingWithTax
  extraShippingWithTax
  extraShippingNoTax
  tax
  totalWithTaxWithoutCoupon
  totalWithTax
  totalNoTax
  mustCheckShippingCost
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderRef",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productsWithTax",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingWithTax",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalWithTax",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tax",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderTotal",
  "kind": "LinkedField",
  "name": "total",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "StockDelta",
  "kind": "LinkedField",
  "name": "stockDeltas",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BraintreeMakePaymentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BraintreeMakePaymentMutationPayload",
        "kind": "LinkedField",
        "name": "braintreeMakePaymentMutation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartTotal",
                "kind": "LinkedField",
                "name": "total",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CartTotalFragment_total"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CartItem",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CartItemFragment_item"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BraintreeMakePaymentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BraintreeMakePaymentMutationPayload",
        "kind": "LinkedField",
        "name": "braintreeMakePaymentMutation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartTotal",
                "kind": "LinkedField",
                "name": "total",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productsNoTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shippingNoTax",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "extraShippingWithTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "extraShippingNoTax",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalWithTaxWithoutCoupon",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalNoTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mustCheckShippingCost",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CartItem",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "qty",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductOptionInCart",
                    "kind": "LinkedField",
                    "name": "option",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v9/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "35d926bddec635f3f74418c133323674",
    "id": null,
    "metadata": {},
    "name": "BraintreeMakePaymentMutation",
    "operationKind": "mutation",
    "text": "mutation BraintreeMakePaymentMutation(\n  $input: BraintreeMakePaymentMutationInput!\n) {\n  braintreeMakePaymentMutation(input: $input) {\n    status\n    cart {\n      id\n      total {\n        id\n        ...CartTotalFragment_total\n      }\n      items {\n        ...CartItemFragment_item\n      }\n    }\n    order {\n      orderRef\n      total {\n        id\n        productsWithTax\n        shippingWithTax\n        totalWithTax\n        tax\n      }\n      id\n    }\n    stockDeltas {\n      id\n      value\n    }\n  }\n}\n\nfragment CartItemFragment_item on CartItem {\n  id\n  name\n  price\n  qty\n  option {\n    title\n    value\n  }\n}\n\nfragment CartTotalFragment_total on CartTotal {\n  id\n  country\n  productsWithTax\n  productsNoTax\n  shippingNoTax\n  shippingWithTax\n  extraShippingWithTax\n  extraShippingNoTax\n  tax\n  totalWithTaxWithoutCoupon\n  totalWithTax\n  totalNoTax\n  mustCheckShippingCost\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ac0e3b93ebfe1af58af36a40d39ea50';

module.exports = node;
