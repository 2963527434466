/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CartOrder_total$ref: FragmentReference;
declare export opaque type CartOrder_total$fragmentType: CartOrder_total$ref;
export type CartOrder_total = {|
  +id: ?string,
  +totalWithTaxWithoutCoupon: ?number,
  +totalWithTax: ?number,
  +$refType: CartOrder_total$ref,
|};
export type CartOrder_total$data = CartOrder_total;
export type CartOrder_total$key = {
  +$data?: CartOrder_total$data,
  +$fragmentRefs: CartOrder_total$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartOrder_total",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithTaxWithoutCoupon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalWithTax",
      "storageKey": null
    }
  ],
  "type": "CartTotal",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '6b40e5e2b76821228c3fb05f6be646ba';

module.exports = node;
