const R = require('ramda');
import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import Markdown from 'react-markdown';
import qs from 'querystring';
import classNames from 'classnames';

const datesAreValid = (dates) => !R.isNil(dates.startDate) && !R.isNil(dates.endDate);

const useDateRange = () => {
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [focusedDate, setFocusedDate] = useState(null);

  const onFocusDateChange = (newFocusedDate) => {
    setFocusedDate(newFocusedDate);
  };

  const onUpdateDates = (range) => {
    setDates(range);
    if (focusedDate === 'endDate') setFocusedDate('startDate');
  };

  return {
    dates,
    onUpdateDates,
    focusedDate,
    onFocusDateChange
  };
};

const buildEmailAttrs = (dates) => {
  if (!datesAreValid(dates)) return '';
  return qs.encode({
    subject: `demande de réservation : du ${dates.startDate.format('DD/MM/YYYY')} au ${dates.endDate.format('DD/MM/YYYY')}`,
    body: ''
  });
};
const Booking = ({ description }) => {

  const {
    dates,
    onUpdateDates,
    focusedDate,
    onFocusDateChange
  } = useDateRange();
  
  return (
    <div className="housing-booking">
      <h2>{'Votre séjour'}</h2>
      <div className="housing-booking-dates">
        <span>{'Vos dates :'}</span>
        <DateRangePicker
          numberOfMonths={1}
          startDate={dates.startDate}
          endDate={dates.endDate}
          onDatesChange={onUpdateDates}
          focusedInput={focusedDate}
          onFocusChange={onFocusDateChange}
          showClearDates={true}
          showDefaultInputIcon={true}
          withPortal={true}
          small={true}
          keepOpenOnDateSelect={true}
          endDatePlaceholderText="Départ"
          startDatePlaceholderText="Arrivée"
          displayFormat="DD/MM/YYYY"
          renderCalendarInfo={() => (
            <button type="button">Apply</button>
          )}
        />
      </div>

      <div className="housing-booking-description">
        <Markdown 
          style={{ marginTop: '16px' }}
          source={description}
        />   
      </div>
      <div className="housing-booking-actions">
        <a 
          disabled={!datesAreValid(dates)}
          className={classNames('uikit-button', { 'uikit-button-disabled': !datesAreValid(dates) })}
          href={`mailto:lpe@lespetitesemplettes.com;hubert.bettan@gmail.com?${buildEmailAttrs(dates)}`}>{'Je réserve'}
        </a>
      </div>
    </div>
  );
};

export default Booking;