import _ from 'lodash';
import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation SignInMutation($input: SignInMutationInput!) {
  signInMutation(input: $input) {
    ok
    error
    user {
      id
      firstname
      lastname
      email
      addresses {
        id
        billingSameAsShipping
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
      }
    }
  }
}
`;

export default (email, password, done) => {
  const variables = {
    input: {
      email,
      password
    },
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.signInMutation;
        done(ok, error);
      },
      onError: err => {
        console.error(err);
      }
    }
  );
};