import React from 'react';

import { Back } from '../uikit/UIKit';
import ProductListDescription from './ProductListDescription';

const NoProducts = ({ category, isAdmin }) => (
  <React.Fragment>
    <div className="block block-large">
      <ProductListDescription isAdmin={isAdmin} category={category} />
      <div className="block-body block-body-center">
        {'Cette catégorie ne contient pas de produits'}
      </div>
    </div>
    <Back />
  </React.Fragment>
);

export default NoProducts;