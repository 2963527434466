import './CartTotal.less';
import React from 'react';
import styled from 'styled-components';
import accounting from 'accounting';
import { graphql, createFragmentContainer } from 'react-relay';
import classNames from 'classnames';

const S_Total = styled.div`
  .cart-line-amount {
    text-align: right;
  }
  .cart-extra-infos {
    width: 100%;
    text-align: center;
    margin-top: 8px;
    font-size: 10px;
    color: #333;
  }
`;

const CartTotal = ({ total, style }) => {

  const renderValue = (title, value, big = false) => (
    <div className="cart-total-row">
      <div className="cart-total-line-title">
        {title}
      </div>
      <div className={classNames('cart-total-line-amount', { 'cart-total-line-bold': big })}>
        {accounting.formatMoney(value, '', 2, ' ', ',')} €
      </div>
    </div>
  );

  const couponValue = total.totalWithTaxWithoutCoupon - total.totalWithTax;
  return (
    <S_Total style={style}>
      {renderValue('Produit(s)', total.productsWithTax)}
      {renderValue('Livraison', total.shippingWithTax)}
      {couponValue > 0 && (
        <>
          {renderValue('Total avant coupon', total.totalWithTaxWithoutCoupon, true)}
          {renderValue('Coupon de réduction', total.totalWithTaxWithoutCoupon - total.totalWithTax)}
        </>
      )}
      {renderValue('Total', total.totalWithTax, true)}
      <div className="cart-extra-infos">
        (total ht: {accounting.formatMoney(total.totalNoTax, '', 2, ' ', ',')} €, tva: {accounting.formatMoney(total.tax, '', 2, ' ', ',')} €)
      </div>
    </S_Total>
  );
};

export default CartTotal;