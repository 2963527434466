const R = require('ramda');
import React from 'react';
import styled from 'styled-components';
import { Motion, spring, presets } from 'react-motion';
import { Link } from 'react-router-dom';

const MenuWrapper = styled.div`
  position: absolute;
  background-color: #e2e2e2;
  color: white;
  min-height: 100%;
  top: 40px;
  z-index: 1000;
  overflow: hidden;
  width: 0px;
`;

const Menu = styled.div`
  padding: 8px 4px 8px 16px;
  color: #444;
`;

const MenuItem = styled.div`
  padding: 4px 0px;
  &>a {
    font-size: 14px;
    color: #444;
  }
`;

const MenuItemL1 = styled(MenuItem)`
  text-transform: uppercase;
`;

const MenuItemL2 = styled(MenuItem)`
  padding-left: 16px;
`;


const MenuMobile = ({
  categories,
  show
}) => {

  const addItem = (name, url, level, key) => {
    return React.createElement(
      level === 1 ? MenuItemL1 : MenuItemL2,
      {
        key
      },
      <React.Fragment>
        { !R.isNil(url) && 
        <Link to={url}>{name}</Link>
        }
        {
          R.isNil(url) &&
          <span>{name}</span>
        }
      </React.Fragment>
    );
  };

  const renderCategories = () => {
    return (
      <div>
        {addItem('Accueil', '/', 1, 'home')}
        {addItem('Mon Compte', '/myaccount', 1, 'myAccount')}
        {addItem('Panier', '/cart', 1, 'cart')}
        {addItem('Commander', '/order', 1, 'order')}
        {
          !R.isNil(categories) && categories.map((category, index) => {
            if (!R.isNil(category)) {
              return (
                <div>
                  <div key={index}>
                    {addItem(category.name, null, 1, index)}
                    {     
                      category.children.map((item, index) => {
                        if (!R.isNil(item)) {
                          return addItem(item.name, `/products/${item.url}`, 2, index);
                        }
                      })
                    }
                  </div>
                </div>
              );
            }
          })
        }
      </div>
    );
  };

  return (
    <div>
      <Motion defaultStyle={{ width: 0 }} style={{ width: spring(show ? window.innerWidth - 10 : 0, presets.stiff) }}>
        {({ width }) => (
          <MenuWrapper style={{ width }}>
            <Menu>
              {renderCategories()}
            </Menu>
          </MenuWrapper>
        )}
      </Motion>
    </div>
  );
};

export default MenuMobile;