import _ from 'lodash';
import {
  commitMutation,
  graphql,
} from 'react-relay';
import Tokens from '../lib/Tokens';

import environment from '../Environment';

const mutation = graphql`
mutation SignUpMutation($input: SignUpMutationInput!) {
  signUpMutation(input: $input) { 
    ok
    error
    user {
      id
      firstname
      lastname
      email
      addresses {
        id
        billingSameAsShipping
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
      }
    }
  }
}
`;

export default (email, firstname, lastname, password, done) => {
  const variables = {
    input: {
      email,
      firstname, 
      lastname,
      password
    },
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {},
      optimisticUpdater: () => {},
      onCompleted: (response) => {
        const { ok, error } = response.signUpMutation;
        done(ok, error);
      },
      onError: err => {
        console.error(err);
      }
    }
  );
};