/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BlogListPageQueryVariables = {||};
export type BlogListPageQueryResponse = {|
  +viewer: ?{|
    +cmsArticles: ?$ReadOnlyArray<?{|
      +id: ?string,
      +title: ?string,
      +thumbnailUrl: ?string,
    |}>
  |}
|};
export type BlogListPageQuery = {|
  variables: BlogListPageQueryVariables,
  response: BlogListPageQueryResponse,
|};
*/


/*
query BlogListPageQuery {
  viewer {
    cmsArticles {
      id
      title
      thumbnailUrl
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Article",
  "kind": "LinkedField",
  "name": "cmsArticles",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BlogListPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BlogListPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d7e2106a9b931ce6d7244a0d9ac5c709",
    "id": null,
    "metadata": {},
    "name": "BlogListPageQuery",
    "operationKind": "query",
    "text": "query BlogListPageQuery {\n  viewer {\n    cmsArticles {\n      id\n      title\n      thumbnailUrl\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4b1f9882d9a4df6b8a4e1edc2940e18e';

module.exports = node;
