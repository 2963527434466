import './OrderShipping.less';
import React from 'react';

const OrderShipping = () => (
  <div className="block order-shipping">
    <div className="flex">
      votre commande sera expédiée via
      <img className="ml-2" style={{ height: '20px' }} src="/images/logoColissimo.png" />
    </div>
    <div>Pour les colis de grande taille nous passons par un transporteur et nous vous contactons avant.</div>
    <div>Merci !</div>
  </div>
);

export default OrderShipping;
