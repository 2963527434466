import _ from 'lodash';
import React, { Component } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { withIsMobile } from '../template/IsMobile';
import Product from './Product';
import ProductMobile from './ProductMobile';
import ProductNotFound from './ProductNotFound';
import { Loading } from '../uikit/UIKit';

const ProductPageQuery = graphql`
  query ProductPageQuery(
    $slug: String
  ) {
    viewer {
      product(slug: $slug) {
        id
        name
        slug
        redirect
        description
        price
        stock
        shippingCost
        url
        pictures {
          filename
        }
        variation {
          name
          default
          options {
            key
            name
            price
            shippingCost
            stock
          }
        }
      }
    }
  } 
`;

class ProductPage extends Component {

  onResize () {
    
  }
  renderProduct () {
    return (
      <QueryRenderer
        environment={environment}
        query={ProductPageQuery}
        variables={{
          slug: this.props.match.params.slug
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props && !this.props.isMobile) {
            if (_.isNull(props.viewer.product)) return <ProductNotFound/>;
            return (
              <Product product={props.viewer.product}/>
            );
          } else if (props && this.props.isMobile) {
            return <ProductMobile product={props.viewer.product}/>;
          }
          return <Loading/>;
        }}
      />
    );
  }
  render () {
    return (
      <div>
        {this.renderProduct()}
      </div>
    );
  }
}

export default withIsMobile(ProductPage);