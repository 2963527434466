/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type OrderState = "cancelled" | "closed" | "new" | "on_its_way" | "packing" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderThumb_order$ref: FragmentReference;
declare export opaque type OrderThumb_order$fragmentType: OrderThumb_order$ref;
export type OrderThumb_order = {|
  +id: ?string,
  +orderRef: ?string,
  +date: ?string,
  +status: ?{|
    +state: ?OrderState
  |},
  +total: ?{|
    +totalWithTax: ?number
  |},
  +$refType: OrderThumb_order$ref,
|};
export type OrderThumb_order$data = OrderThumb_order;
export type OrderThumb_order$key = {
  +$data?: OrderThumb_order$data,
  +$fragmentRefs: OrderThumb_order$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderThumb_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderTotal",
      "kind": "LinkedField",
      "name": "total",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalWithTax",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'a641a38babc8d5935813d69c29181116';

module.exports = node;
