import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation AddToCartMutation($input: AddToCartMutationInput!) {
    addToCartMutation(input: $input) { 
      ok,
      error,
      cart {
        id
        nbOfItems
        total {
          productsWithTax
        }
      }
    }
  }
`;

export default (productId, price, qty, optionKey, done) => {
  const variables = {
    input: {
      productId,
      price,
      qty,
      optionKey,
      clientMutationId: ''
    },
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (store) => {
        /*
        const root = store.getRootField('addToCart');
        const nbOfItems = root.getValue('nbOfItems');
        const cartTop = store.get('cartTop');
        cartTop.setValue(nbOfItems, 'nbOfItems');
        */
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};