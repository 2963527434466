import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation UpdateBillingSameAsShippingMutation($input: UpdateBillingSameAsShippingMutationInput!) {
  updateBillingSameAsShippingMutation(input: $input) { 
    ok
    error
    user {
      addresses {
        id
        billingSameAsShipping
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
      }
    }
  }
}
`;

export default (value, done) => {
  const variables = {
    input: {
      billingSameAsShipping: value
    }
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};