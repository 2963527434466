import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import update from 'react-addons-update';
import styled from 'styled-components';

import { Button, Input } from '../../uikit/UIKit';

import SignInUserMutation from '../../../mutations/SignInMutation';

const S_SignIn = styled.form`
  flex: 1;
`;

class SignIn extends Component {
  constructor () {
    super();
    this.state = {
      email: '',
      password: '',
      error: ''
    };
  }

  updateField (fieldname, value) {
    this.setState(update(this.state, { 
      [fieldname]: { $set: value }, showMessage: { $set: false },
      error: { $set: '' }
    }));
  }

  submit (e) {
    const { email, password } = this.state;
    e.preventDefault();
    SignInUserMutation(email, password, (ok, error) => {
      this.setState(update(this.state, { error: { $set: error } }));
    });
  }

  renderMessage () {
    const { error } = this.state;
    return (
      <div>
        {
          !_.isEmpty(error) &&
          <div className="block-error">
            {SignIn.Messages[error]}
          </div>
        }
      </div>
    );
  }

  render () {
    const { email, password } = this.state;
    return (
      <S_SignIn onSubmit={(e) => this.submit(e)}>
        <h3>{'J\'ai un compte'}</h3>
        <Input title="Votre email" value={email} onChange={(v) => this.updateField('email', v)}/>
        <Input title="Votre mot de passe" type="password" value={password} onChange={(v) => this.updateField('password', v)}/>
        <Button style={{ marginTop: '12px' }} type="submit" small={true}>Se connecter</Button>
        <Link to="/account/lostpassword" style={{ marginLeft: '16px' }}className="block-link">{'j\'ai perdu mon mot de passe'}</Link>
        {this.renderMessage()}
      </S_SignIn>
    );
  }
}

SignIn.Messages = {
  'PASSWORD_KO': 'Email ou mot de passe incorrect'
};

export default SignIn;