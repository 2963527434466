/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddToCartMutationInput = {|
  productId?: ?string,
  price?: ?number,
  qty?: ?number,
  optionKey?: ?string,
  clientMutationId?: ?string,
|};
export type AddToCartMutationVariables = {|
  input: AddToCartMutationInput
|};
export type AddToCartMutationResponse = {|
  +addToCartMutation: ?{|
    +ok: ?boolean,
    +error: ?string,
    +cart: ?{|
      +id: ?string,
      +nbOfItems: ?number,
      +total: ?{|
        +productsWithTax: ?number
      |},
    |},
  |}
|};
export type AddToCartMutation = {|
  variables: AddToCartMutationVariables,
  response: AddToCartMutationResponse,
|};
*/


/*
mutation AddToCartMutation(
  $input: AddToCartMutationInput!
) {
  addToCartMutation(input: $input) {
    ok
    error
    cart {
      id
      nbOfItems
      total {
        productsWithTax
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nbOfItems",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productsWithTax",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddToCartMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddToCartMutationPayload",
        "kind": "LinkedField",
        "name": "addToCartMutation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartTotal",
                "kind": "LinkedField",
                "name": "total",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddToCartMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddToCartMutationPayload",
        "kind": "LinkedField",
        "name": "addToCartMutation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartTotal",
                "kind": "LinkedField",
                "name": "total",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "87c187fabf4515962c7847fd0ccb7f6f",
    "id": null,
    "metadata": {},
    "name": "AddToCartMutation",
    "operationKind": "mutation",
    "text": "mutation AddToCartMutation(\n  $input: AddToCartMutationInput!\n) {\n  addToCartMutation(input: $input) {\n    ok\n    error\n    cart {\n      id\n      nbOfItems\n      total {\n        productsWithTax\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bccc096a0d5c21c8fc76aa2fe5e45ab4';

module.exports = node;
