import './OrderTunnelPage.less';
import _ from 'lodash';
import React, { Component } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import url from 'url';

import OrderAccount from './OrderAccount';
import OrderAddresses from './OrderAddresses';
import OrderMessage from './OrderMessage';
import OrderCart from './OrderCart';
import OrderPayment from './OrderPayment';
import OrderShipping from './OrderShipping';
import { Redirect } from 'react-router-dom';
import UpdateCartCountryMutation from '../../mutations/UpdateCartCountryMutation';

import { Loading } from '../uikit/UIKit';

//mport { WindowResizeListener } from 'react-window-resize-listener';

const OrderTunnelPageQuery = graphql`
query OrderTunnelPageQuery {
  viewer {
    user {
      id
      firstname
      lastname
      email
      addresses {
        id
        billingSameAsShipping
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
      }
    }
    cart {
      id
      total {
        ...CartTotalFragment_total
        totalWithTax
      }
      items {
        ...CartItemFragment_item
      }
    }
    braintreeToken
  }
} 
`;


class OrderTunnelPage extends Component {
  constructor() {
    super();

    this.state = {
      stockDeltas: [], //{ 'id': '5985bbab17cde90aed5d2d4c:p43qxc', 'value': -2 }],
      message: '',
      orderRef: '',
      couponError: false
    };
  }

  setCouponError() {
    this.setState({ ...this.state, couponError: true });
  }

  setOutOfStock(stockDeltas) {
    this.setState({ ...this.state, stockDeltas });
  }

  setPaymentError() {
    this.setState({ ...this.state, paymentError: true });
  }

  setOrderDone(orderRef) {
    this.setState({ ...this.state, orderRef });
  }

  onShippingCountryChange(country) {
    UpdateCartCountryMutation(country, () => {
    });
  }

  updateMessage(message) {
    this.setState({ ...this.state, message });
  }

  render() {
    const { match } = this.props;
    const { stockDeltas, orderRef, couponError } = this.state;

    if (!_.isEmpty(orderRef)) return <Redirect to={url.format({ pathname: '/order/success', query: { ref: orderRef } })} />;

    return (
      <div>
        <QueryRenderer
          environment={environment}
          query={OrderTunnelPageQuery}
          render={({ error, props }) => {
            if (error) {
              return <div>{error.message}</div>;
            } else if (props) {
              const { user, cart, braintreeToken } = props.viewer;
              return (
                <div>
                  {
                    <OrderCart
                      {...{ stockDeltas, items: cart.items, total: cart.total }}
                    />
                  }
                  {
                    !_.isEmpty(cart.items) &&
                    <OrderAccount {...{ user }} />
                  }
                  {
                    !_.isEmpty(cart.items) && !_.isEmpty(user.email) &&
                    <OrderAddresses
                      addresses={user.addresses}
                      onShippingCountryChange={country => this.onShippingCountryChange(country)}
                    />
                  }
                  {
                    !_.isEmpty(cart.items) && !_.isEmpty(user.email) &&
                    <OrderMessage
                      value={this.state.message}
                      onUpdate={message => this.updateMessage(message)}
                    />
                  }
                  {
                    !_.isEmpty(cart.items) && !_.isEmpty(user.email) && !_.isNull(user.addresses.shipping) &&
                    <React.Fragment>
                      <OrderPayment
                        {...{ braintreeToken }}
                        amount={cart.total.totalWithTax}
                        message={this.state.message}
                        outOfStock={!_.isEmpty(stockDeltas)}
                        couponError={couponError}
                        onOutOfStock={stockDeltas => this.setOutOfStock(stockDeltas)}
                        onCouponError={() => this.setCouponError()}
                        onOrderDone={(url) => this.setOrderDone(url)}
                      />
                      <OrderShipping />
                    </React.Fragment>
                  }

                </div>
              );
            }
            return <Loading />;
          }}
        />
      </div>
    );
  }
}

export default OrderTunnelPage;