import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'react-addons-update';
import styled from 'styled-components';
import { Input, Button } from '../../uikit/UIKit';

import Address from './Address';

const S_ShippingAddress = styled.div`
`;

class ShippingAddress extends Component {
  render () {
    const { address } = this.props;
    return (
      <S_ShippingAddress>
        <h3 className="title-3">Votre adresse de livraison</h3>
        <Address
          {...{ address, type: 'shipping' }}
          onCountryChange={(country) => {
            if (!_.isUndefined(this.props.onCountryChange)) this.props.onCountryChange(country);
          }}
        />
      </S_ShippingAddress>
    );
  }
}

ShippingAddress.propTypes = {
  onCountryChange: PropTypes.func
};

export default ShippingAddress;