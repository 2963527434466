import React from 'react';
import { Button } from '../uikit/UIKit';

const OrderPaymentError = () => (
  <div className="block">
    <div className="block-body block-body-error">
      <div>
        {'Le paiement n\'a pas pu aboutir, nous sommes désolés'}
      </div>
      <Button
        to="/order"
        style={{ marginTop: '16px' }}
      >
        {'Essayer de nouveau'}
      </Button>
    </div>
  </div>
);

export default OrderPaymentError;