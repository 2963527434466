import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation UpdateAddressMutation($input: UpdateAddressMutationInput!) {
  updateAddressMutation(input: $input) { 
    ok
    error
    user {
      addresses{
        id
        billingSameAsShipping
        shipping {
          ...Address_address
        }
        billing {
          ...Address_address
        }
      }
    }
  }
}
`;

export default (
  {
    firstname,
    lastname,
    companyName,
    phone,
    field1,
    field2,
    field3,
    city,
    postcode,
    country,
    extra,
    active,
    type
  },
  done
) => {
  const variables = {
    input: {
      firstname,
      lastname,
      companyName,
      phone,
      field1,
      field2,
      field3,
      city,
      postcode,
      country,
      extra,
      active,
      type
    },
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: proxyStore => {
      },
      optimisticUpdater: proxyStore => {
      },
      onCompleted: () => {
        done();
      },
      onError: err => console.error(err),
    }
  );
};