/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CartItemFragment_item$ref = any;
type CartOrder_total$ref = any;
type CartTotalFragment_total$ref = any;
export type CartPageQueryVariables = {||};
export type CartPageQueryResponse = {|
  +viewer: ?{|
    +cart: ?{|
      +id: ?string,
      +coupon: ?{|
        +id: ?string,
        +code: ?string,
        +amount: ?number,
      |},
      +total: ?{|
        +id: ?string,
        +$fragmentRefs: CartTotalFragment_total$ref & CartOrder_total$ref,
      |},
      +items: ?$ReadOnlyArray<?{|
        +$fragmentRefs: CartItemFragment_item$ref
      |}>,
    |}
  |}
|};
export type CartPageQuery = {|
  variables: CartPageQueryVariables,
  response: CartPageQueryResponse,
|};
*/


/*
query CartPageQuery {
  viewer {
    cart {
      id
      coupon {
        id
        code
        amount
      }
      total {
        id
        ...CartTotalFragment_total
        ...CartOrder_total
      }
      items {
        ...CartItemFragment_item
      }
    }
    id
  }
}

fragment CartItemFragment_item on CartItem {
  id
  name
  price
  qty
  option {
    title
    value
  }
}

fragment CartOrder_total on CartTotal {
  id
  totalWithTaxWithoutCoupon
  totalWithTax
}

fragment CartTotalFragment_total on CartTotal {
  id
  country
  productsWithTax
  productsNoTax
  shippingNoTax
  shippingWithTax
  extraShippingWithTax
  extraShippingNoTax
  tax
  totalWithTaxWithoutCoupon
  totalWithTax
  totalNoTax
  mustCheckShippingCost
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Coupon",
  "kind": "LinkedField",
  "name": "coupon",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CartPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartTotal",
                "kind": "LinkedField",
                "name": "total",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CartTotalFragment_total"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CartOrder_total"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CartItem",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CartItemFragment_item"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CartPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CartTotal",
                "kind": "LinkedField",
                "name": "total",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productsWithTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productsNoTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shippingNoTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shippingWithTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "extraShippingWithTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "extraShippingNoTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalWithTaxWithoutCoupon",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalWithTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalNoTax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mustCheckShippingCost",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CartItem",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "qty",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductOptionInCart",
                    "kind": "LinkedField",
                    "name": "option",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e4451305fee9d50408cfc446d12e1bf",
    "id": null,
    "metadata": {},
    "name": "CartPageQuery",
    "operationKind": "query",
    "text": "query CartPageQuery {\n  viewer {\n    cart {\n      id\n      coupon {\n        id\n        code\n        amount\n      }\n      total {\n        id\n        ...CartTotalFragment_total\n        ...CartOrder_total\n      }\n      items {\n        ...CartItemFragment_item\n      }\n    }\n    id\n  }\n}\n\nfragment CartItemFragment_item on CartItem {\n  id\n  name\n  price\n  qty\n  option {\n    title\n    value\n  }\n}\n\nfragment CartOrder_total on CartTotal {\n  id\n  totalWithTaxWithoutCoupon\n  totalWithTax\n}\n\nfragment CartTotalFragment_total on CartTotal {\n  id\n  country\n  productsWithTax\n  productsNoTax\n  shippingNoTax\n  shippingWithTax\n  extraShippingWithTax\n  extraShippingNoTax\n  tax\n  totalWithTaxWithoutCoupon\n  totalWithTax\n  totalNoTax\n  mustCheckShippingCost\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '63ec97120a748602d714a7aed796c644';

module.exports = node;
