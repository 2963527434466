import './CartItem.less';
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { graphql, createFragmentContainer } from 'react-relay';
import accounting from 'accounting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle, faTimesCircle, faTimes } from '@fortawesome/pro-light-svg-icons';
import UpdateCartItemQtyMutation from '../../mutations/UpdateCartItemQtyMutation';
import RemoveCartItemMutation from '../../mutations/RemoveCartItemMutation';

class CartItem extends Component {
  constructor(props) {
    super();
    this.state = {
      addOneIsDisabled: false,
      removeOneIsDisabled: false,
      removeItemIsDisabled: false,
      showRemove: false
    };
  }
  updateQty(delta) {
    const { id } = this.props.item;
    this.setState({ addOneIsDisabled: delta === 1, removeOneIsDisabled: delta === -1 });
    UpdateCartItemQtyMutation(id, delta, () => {
      this.setState({ addOneIsDisabled: false, removeOneIsDisabled: false });
    });
  }

  remove() {
    const { id } = this.props.item;
    this.setState({ addOneIsDisabled: true, removeOneIsDisabled: true, removeItemIsDisabled: true });

    RemoveCartItemMutation(id, () => {

    });
  }

  renderBtnAddOne() {
    const { addOneIsDisabled } = this.state;
    if (addOneIsDisabled) {
      return (
        <span className="icon-disabled"><FontAwesomeIcon icon={faPlusCircle} size="2x" /></span>
      );
    } else {
      return (
        <a className="icon-enabled" onClick={() => this.updateQty(+1)}><FontAwesomeIcon icon={faPlusCircle} size="2x" /></a>
      );
    }
  }

  renderBtnRemoveOne() {
    const { removeOneIsDisabled } = this.state;
    const { qty } = this.props.item;
    if (removeOneIsDisabled || qty === 0) {
      return (
        <span className="icon-disabled"><FontAwesomeIcon icon={faMinusCircle} size="2x" /></span>
      );
    } else {
      return (
        <a className="icon-enabled" onClick={() => this.updateQty(-1)}><FontAwesomeIcon icon={faMinusCircle} size="2x" /></a>
      );
    }
  }

  renderRemoveItem() {
    const { removeItemIsDisabled } = this.state;
    if (removeItemIsDisabled) {
      return <span className="icon-disabled" onClick={() => this.remove()}><FontAwesomeIcon name={faTimesCircle} /></span>;

    }
    return <a className="icon-enabled" onClick={() => this.remove()}><FontAwesomeIcon name={faTimesCircle} /></a>;
  }

  renderQty() {
    const { delta, readOnly } = this.props;
    const { id, name, qty, price, option } = this.props.item;
    return (
      <div className="cart-item-qty">
        <div>qté</div>
        <div className="cart-qty-updater">

          <div className={classnames('qty-value', { 'out-of-stock': readOnly && delta < 0 })}>{qty}</div>
          {this.renderBtnAddOne()}
        </div>
      </div>
    );
  }

  renderOutOfStock(qty) {
    const { delta } = this.props;

    return (
      <div className="out-of-stock">
        Qté max : <b>{qty + delta}</b>
      </div>
    );
  }

  renderOption() {
    const getOptionName = () => {
      return this.props.item.option.name || this.props.item.option.title;
    };

    const getOptionValue = () => {
      if (_.isObject(this.props.item.option.value)) {
        return this.props.item.option.value.name;
      }
      return this.props.item.option.value;
    };

    if (_.isUndefined(this.props.item.option) || _.isNull(this.props.item.option)) return null;

    return (
      <div className="cart-item-product-option">
        {getOptionName() !== '' ? `${getOptionName()} - ${getOptionValue()}` : ''}
      </div>
    );

  }
  render() {
    if (_.isUndefined(this.props.item)) return null;

    const { delta } = this.props;
    const { id, name, qty, price, option } = this.props.item;
    const { readOnly } = this.props;
    const { showRemove } = this.state;
    return (
      <div className="cart-item">
        <div className="cart-item-image">
          <div>
            {!this.props.readOnly && this.renderBtnRemoveOne()}
          </div>
          <div
            className="cart-image"
          >
            <img src={`/product/${id}/image/100-3-2`} className="lpe-image" />
            {
              !this.props.readOnly &&
              <div className="cart-image-remove">
                <a href="javascript:void(0);" onClick={() => this.remove()}><FontAwesomeIcon icon={faTimes} /></a>
              </div>
            }
            <div className="cart-image-qty">
              {qty}
            </div>
          </div>
          <div>
            {!this.props.readOnly && this.renderBtnAddOne()}
          </div>
        </div>
        <div>
          <Link className="cart-item-product-title" to={`/product/${id}`}>{name}</Link>
          {this.renderOption()}
        </div>
        <div className="cart-item-product-price">
          {accounting.formatMoney(price, '', 2, ' ', ',')}&nbsp;€
        </div>
        {
          readOnly && delta < 0 &&
          <div className="cart-item-message-out-of-stock">
            {
              qty + delta === 0 ?
                'Ce produit n\'est plus en stock' :
                `Seulement ${qty + delta} exemplaire(s) en stock`
            }
          </div>
        }
      </div>
    );
  }
}

CartItem.defaultProps = {
  readOnly: false,
  delta: 0
};

CartItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    qty: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    option: PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired,
  }),
  readOnly: PropTypes.bool,
  delta: PropTypes.number
};

export default CartItem;
