import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Loading } from '../uikit/UIKit';

import {
  Link
} from 'react-router-dom';

import { 
  Housing
} from '.';

const HousingPageQuery = graphql`
  query HousingPageQuery($slug: String) {
    viewer {
      cmsHousing(slug: $slug) {
        id
        title
        description
        meta {
          description
        }
        picture1 {
          title
          url
        }
        picture2 {
          title
          url
        }
        rooms {
          title
          description
          gallery {
            title
            url
          }
        }
        bookingDescription
      }
    }
  }
`;

const HousingPage = ({ slug }) => (
  <div className="blog-article">
    <Link to="/housings">{'Nos hébergements'}</Link>
    <QueryRenderer
      environment={environment}
      query={HousingPageQuery}
      variables={{
        slug
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          return <Housing
            value={props.viewer.cmsHousing}
          />;
        }
        return <Loading/>;
      }}
    />
  </div>
);

export default HousingPage;